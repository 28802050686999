.wj-series-group rect {
  stroke: none;
}

.wj-flexchart .wj-header .wj-title {
  font-size: 20px;
  font-weight: bold;
  color: #5B5C5B;
}

.wj-flexchart .wj-label {
  font-size: 15px;
  fill: #979998;
}

.wj-flexchart .wj-axis-x .wj-label {
  font-size: 16px;
  fill: #5B5C5B;
}

.wj-flexchart .wj-gridline {
  stroke: #B9BCBB;
}