*,
*:focus,
*:active {
  outline: none !important;
}

div[role=presentation].MuiPopover-root .MuiPaper-root {
  border-radius: 0px !important;
  border: 1px solid #B9BCBB;
  background: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.17), 0 4px 5px rgba(0, 0, 0, 0.11);
  max-height: 208px;
  overflow: overlay !important;
  margin-top: 2px;
}

div[role=presentation].MuiPopover-root .MuiPaper-root::-webkit-scrollbar {
  width: 6px;
}

div[role=presentation].MuiPopover-root .MuiPaper-root::-webkit-scrollbar-track {
  background: transparent;
}

div[role=presentation].MuiPopover-root .MuiPaper-root::-webkit-scrollbar-thumb {
  background: rgba(60, 62, 61, 0.4);
  width: 6px;
  border-radius: 3px;
}

div[role=presentation].MuiPopover-root .MuiPaper-root li.MuiMenuItem-root {
  height: 32px;
  padding: 6px 8px !important;
  position: relative;
  font-size: 13px;
  font-weight: 400 !important;
  color: #000000;
  font-family: "Malgun Gothic", "Segoe UI", "MS YaHei", sans-serif;
}

div[role=presentation].MuiPopover-root .MuiPaper-root li.MuiMenuItem-root:hover {
  background: #EEEAF8 !important;
}

div[role=presentation].MuiPopover-root .MuiPaper-root li.MuiMenuItem-root.check {
  background: #EEEAF8 !important;
}

div[role=presentation].MuiPopover-root .MuiPaper-root li.MuiMenuItem-root.check:before {
  content: "";
  clear: both;
  display: inline-block;
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background: #554596;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.8243 6.37593C22.0586 6.61025 22.0586 6.99014 21.8243 7.22446L11.1363 17.9124C10.3163 18.7324 8.98684 18.7326 8.1667 17.9127L2.17581 11.9239C1.94145 11.6896 1.94139 11.3097 2.17566 11.0754C2.40993 10.841 2.78983 10.841 3.02419 11.0752L9.01508 17.064C9.36657 17.4154 9.93633 17.4153 10.2878 17.0639L20.9757 6.37593C21.2101 6.14162 21.5899 6.14162 21.8243 6.37593Z' fill=''/%3E%3C/svg%3E%0A");
  mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.8243 6.37593C22.0586 6.61025 22.0586 6.99014 21.8243 7.22446L11.1363 17.9124C10.3163 18.7324 8.98684 18.7326 8.1667 17.9127L2.17581 11.9239C1.94145 11.6896 1.94139 11.3097 2.17566 11.0754C2.40993 10.841 2.78983 10.841 3.02419 11.0752L9.01508 17.064C9.36657 17.4154 9.93633 17.4153 10.2878 17.0639L20.9757 6.37593C21.2101 6.14162 21.5899 6.14162 21.8243 6.37593Z' fill=''/%3E%3C/svg%3E%0A");
}

div[role=presentation].MuiPopover-root .MuiPaper-root li.MuiMenuItem-root.check .MuiCheckbox-root {
  border: 1px solid #2D9BB2;
  background: #2D9BB2;
}

div[role=presentation].MuiPopover-root .MuiPaper-root li.MuiMenuItem-root.check .MuiCheckbox-root:before {
  content: "";
  clear: both;
  display: inline-block;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.6343 5.23431C11.9467 4.9219 12.4533 4.9219 12.7657 5.23431C13.0781 5.54673 13.0781 6.05327 12.7657 6.36568L7.4217 11.7097C6.81646 12.3149 5.8352 12.315 5.22986 11.7099L2.23441 8.71548C1.92194 8.40311 1.92185 7.89658 2.23422 7.58411C2.54658 7.27163 3.05311 7.27154 3.36559 7.58391L6.32567 10.543L11.6343 5.23431Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
}

div[role=presentation].MuiPopover-root .MuiPaper-root li.MuiMenuItem-root .MuiInputBase-input {
  padding: 8px !important;
  box-sizing: border-box !important;
}

div[role=presentation].MuiPopover-root .MuiPaper-root li.MuiMenuItem-root .MuiCheckbox-root {
  padding: 0px !important;
  display: inline-block;
  position: absolute;
  top: 8px;
  left: 6px;
  width: 16px;
  height: 16px;
  background: #FFFFFF;
  border: 1px solid #B9BCBB;
  border-radius: 2px;
}

div[role=presentation].MuiPopover-root .MuiPaper-root li.MuiMenuItem-root .MuiCheckbox-root svg {
  display: none;
}

div[role=presentation].MuiPopover-root .MuiPaper-root li.MuiMenuItem-root .MuiTypography-root {
  padding-left: 20px;
  font-size: 13px;
  font-weight: 400 !important;
  color: #000000;
  font-family: "Malgun Gothic", "Segoe UI", "MS YaHei", sans-serif;
}

div[role=listbox].wj-grid-listbox,
div[wj-part=dropdown].wj-listbox {
  border: 1px solid #B9BCBB;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.17), 0 4px 5px rgba(0, 0, 0, 0.11) !important;
  max-height: 160px !important;
  border-radius: 2px !important;
}

div[role=listbox].wj-grid-listbox::-webkit-scrollbar,
div[wj-part=dropdown].wj-listbox::-webkit-scrollbar {
  width: 6px;
}

div[role=listbox].wj-grid-listbox::-webkit-scrollbar-track,
div[wj-part=dropdown].wj-listbox::-webkit-scrollbar-track {
  background: transparent;
}

div[role=listbox].wj-grid-listbox::-webkit-scrollbar-thumb,
div[wj-part=dropdown].wj-listbox::-webkit-scrollbar-thumb {
  background: rgba(60, 62, 61, 0.4);
  width: 6px;
  border-radius: 3px;
}

div[role=listbox].wj-grid-listbox div[role=option].wj-listbox-item,
div[wj-part=dropdown].wj-listbox div[role=option].wj-listbox-item {
  height: 32px;
  font-size: 13px;
  font-weight: 400;
  color: #000000;
}

div[role=listbox].wj-grid-listbox div[role=option].wj-listbox-item:first-child,
div[wj-part=dropdown].wj-listbox div[role=option].wj-listbox-item:first-child {
  margin-top: 8px;
}

div[role=listbox].wj-grid-listbox div[role=option].wj-listbox-item:last-child,
div[wj-part=dropdown].wj-listbox div[role=option].wj-listbox-item:last-child {
  margin-bottom: 8px;
}

div[role=listbox].wj-grid-listbox div[role=option].wj-listbox-item:hover,
div[wj-part=dropdown].wj-listbox div[role=option].wj-listbox-item:hover {
  cursor: pointer;
  background: #EEEAF8 !important;
}

div[role=listbox].wj-grid-listbox div[role=option].wj-listbox-item.wj-state-selected,
div[wj-part=dropdown].wj-listbox div[role=option].wj-listbox-item.wj-state-selected {
  background: #EEEAF8 !important;
  position: relative;
}

div[role=listbox].wj-grid-listbox div[role=option].wj-listbox-item.wj-state-selected:before,
div[wj-part=dropdown].wj-listbox div[role=option].wj-listbox-item.wj-state-selected:before {
  content: "";
  clear: both;
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.5497 4.25058C14.7059 4.40679 14.7059 4.66006 14.5497 4.81627L7.42435 11.9416C6.87769 12.4883 5.99139 12.4883 5.44463 11.9418L1.4507 7.94924C1.29447 7.79305 1.29442 7.53979 1.4506 7.38355C1.60679 7.22731 1.86005 7.22727 2.01629 7.38345L6.01021 11.376C6.24454 11.6102 6.62438 11.6102 6.85867 11.3759L13.984 4.25058C14.1402 4.09437 14.3935 4.09437 14.5497 4.25058Z' fill='%23554596'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px 16px;
}

div[wj-part=dropdown].wj-inputdate-dropdown {
  border: none !important;
  outline: none !important;
}

div[wj-part=dropdown].wj-inputdate-dropdown:focus,
div[wj-part=dropdown].wj-inputdate-dropdown:active {
  border: none !important;
  outline: none !important;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar {
  border: none !important;
  outline: none !important;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar:focus,
div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar:active {
  border: none !important;
  outline: none !important;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-content.wj-calendar-outer {
  background: #FFFFFF;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.17), 0 4px 5px rgba(0, 0, 0, 0.11) !important;
  border-radius: 2px !important;
  padding: 8px 20px 20px 20px;
  border: none !important;
  outline: none !important;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-content.wj-calendar-outer:focus,
div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-content.wj-calendar-outer:active {
  border: none !important;
  outline: none !important;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar div[wj-part=tbl-header].wj-calendar-header {
  height: 32px;
  margin-bottom: 8px;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar div[wj-part=tbl-header].wj-calendar-header .wj-month-select {
  height: 32px;
  margin: 0px !important;
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  color: #000000;
  position: relative;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar div[wj-part=tbl-header].wj-calendar-header .wj-month-select:before {
  content: "";
  clear: both;
  display: inline-block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  right: -16px;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 10 10' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.16673 6.59291C5.12245 6.64011 5.06239 6.66663 4.99976 6.66663C4.93714 6.66663 4.87708 6.64011 4.83279 6.59291L2.56891 4.17947C2.53599 4.14427 2.51358 4.09946 2.50451 4.05069C2.49544 4.00193 2.50012 3.95139 2.51795 3.90545C2.53578 3.85952 2.56597 3.82024 2.60471 3.79257C2.64345 3.76489 2.68901 3.75007 2.73564 3.74996L7.26341 3.74996C7.31018 3.74987 7.35592 3.76459 7.39484 3.79225C7.43375 3.81991 7.46409 3.85926 7.482 3.90532C7.49991 3.95138 7.50459 4.00207 7.49544 4.05097C7.48629 4.09987 7.46373 4.14477 7.43062 4.17998L5.16673 6.59291Z' fill=''/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 10px 10px;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar div[wj-part=tbl-header].wj-calendar-header .wj-month-select .wj-glyph-down {
  display: none;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar div[wj-part=tbl-header].wj-calendar-header .wj-btn-group {
  border: none !important;
  display: inline-flex;
  align-items: center;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar div[wj-part=tbl-header].wj-calendar-header .wj-btn-group button {
  border: none !important;
  width: 32px;
  height: 32px;
  position: relative;
  border-radius: 0px !important;
  padding: 0px !important;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar div[wj-part=tbl-header].wj-calendar-header .wj-btn-group button:before {
  content: "";
  clear: both;
  display: inline-block;
  width: 16px;
  height: 16px;
  transform: rotate(90deg);
  background-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 20 20' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.50172 12.9773C9.78589 13.2299 10.2141 13.2299 10.4983 12.9773L17.1678 7.04882C17.3742 6.86536 17.6902 6.88395 17.8737 7.09034C18.0572 7.29673 18.0386 7.61277 17.8322 7.79623L11.1626 13.7247C10.4996 14.3141 9.50041 14.3141 8.83736 13.7247L2.16781 7.79623C1.96142 7.61277 1.94283 7.29673 2.12629 7.09034C2.30975 6.88395 2.62579 6.86536 2.83218 7.04882L9.50172 12.9773Z' fill=''/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px 16px;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar div[wj-part=tbl-header].wj-calendar-header .wj-btn-group button span {
  display: none !important;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar div[wj-part=tbl-header].wj-calendar-header .wj-btn-group button:hover {
  background: none !important;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar div[wj-part=tbl-header].wj-calendar-header .wj-btn-group button[wj-part=btn-today] {
  display: none;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar div[wj-part=tbl-header].wj-calendar-header .wj-btn-group button[wj-part=btn-next]:before {
  transform: rotate(270deg);
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-month .wj-state-selected {
  background: none !important;
  position: relative;
  font-weight: 700;
  color: #FFFFFF;
  z-index: 99;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-month .wj-state-selected:before {
  content: "";
  clear: both;
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background: #2D9BB2;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-month .wj-header {
  color: #979998;
  background: none !important;
  font-weight: 400 !important;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-month td {
  font-size: 12px;
  padding: 9px 0px 0px 0px !important;
  width: 36px;
  height: 36px;
  color: #000000;
  cursor: pointer;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-month td.wj-day-today {
  position: relative;
  font-weight: 700;
  color: #FFFFFF;
  z-index: 99;
  background: none;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-month td.wj-day-today:before {
  content: "";
  clear: both;
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background: #3C3E3D;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-month td.wj-day-othermonth {
  color: #000000;
  opacity: 0.4;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-month td:focus,
div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-month td:active {
  position: relative;
  font-weight: 700;
  color: #FFFFFF;
  z-index: 99;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-month td:focus:before,
div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-month td:active:before {
  content: "";
  clear: both;
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background: #2D9BB2;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-year {
  margin-top: 20px;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-year .wj-header {
  display: none;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-year td {
  width: 63px;
  height: 32px;
  font-size: 13px;
  color: #000000;
  padding: 6px 0px 0px 0px !important;
  cursor: pointer;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-year td:focus,
div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-year td:active {
  position: relative;
  color: #FFFFFF;
  z-index: 99;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-year td:focus:before,
div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-year td:active:before {
  content: "";
  clear: both;
  display: inline-block;
  width: 63px;
  height: 32px;
  border-radius: 2px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background: #2D9BB2;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-year td.wj-state-selected {
  position: relative;
  color: #FFFFFF;
  z-index: 99;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar .wj-calendar-year td.wj-state-selected:before {
  content: "";
  clear: both;
  display: inline-block;
  width: 63px;
  height: 32px;
  border-radius: 2px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background: #2D9BB2;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar.wj-calendar-multimonth .wj-state-selected {
  position: relative;
  color: #FFFFFF;
  z-index: 99;
  background: none;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar.wj-calendar-multimonth .wj-state-selected:before {
  content: "";
  clear: both;
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background: #2D9BB2;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar.wj-calendar-multimonth .wj-state-multi-selected {
  background: #D9FCF4 !important;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar.wj-calendar-multimonth .wj-state-multi-selected.wj-state-last-selected {
  position: relative;
  color: #FFFFFF;
  z-index: 99;
  background: none !important;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar.wj-calendar-multimonth .wj-state-multi-selected.wj-state-last-selected:before {
  content: "";
  clear: both;
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background: #2D9BB2;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar.wj-calendar-multimonth .wj-state-multi-selected.wj-state-last-selected:after {
  content: "";
  clear: both;
  display: inline-block;
  width: 18px;
  height: 36px;
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  background: #D9FCF4;
}

div[wj-part=dropdown].wj-inputdate-dropdown .wj-calendar.wj-calendar-multimonth .wj-state-selected:after {
  content: "";
  clear: both;
  display: inline-block;
  width: 18px;
  height: 36px;
  position: absolute;
  z-index: -2;
  top: 0;
  left: 18px;
  background: #D9FCF4;
}

.wj-tooltip.hdr-tip {
  max-width: 500px;
  border-radius: 2px;
  padding: 4px 8px;
  font-size: 12px;
  color: #FFFFFF;
  background: #3C3E3D;
  word-break: break-all;
  white-space: pre-line;
}

.wj-popup.wj-state-focused {
  padding: 8px;
  border-radius: 2px;
  width: 200px;
  height: 100px;
}

.wj-popup.wj-state-focused>div {
  position: relative;
}

.wj-popup.wj-state-focused>div:has(textarea:focus) {
  border: 1px solid #B9BCBB;
}

.wj-popup.wj-state-focused p {
  position: absolute;
  bottom: 0;
  left: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #5B5C5B;
  height: 24px;
}

.wj-popup.wj-state-focused div:has(button) {
  display: flex !important;
  align-items: center;
  justify-content: right;
  margin-top: 4px;
}

.wj-dropdown-panel:has(.wj-multiselectlistbox) {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.17), 0 4px 5px rgba(0, 0, 0, 0.11) !important;
  border: 1px solid #B9BCBB;
  border-radius: 2px;
}

.wj-multiselectlistbox .wj-listbox {
  max-height: 200px !important;
}

.wj-multiselectlistbox .wj-listbox::-webkit-scrollbar {
  width: 6px;
}

.wj-multiselectlistbox .wj-listbox::-webkit-scrollbar-track {
  background: transparent;
}

.wj-multiselectlistbox .wj-listbox::-webkit-scrollbar-thumb {
  background: rgba(60, 62, 61, 0.4);
  width: 6px;
  border-radius: 3px;
}

.wj-multiselectlistbox .wj-listbox .wj-listbox-item {
  height: 32px;
  padding: 6px 8px 6px 32px;
}

.wj-multiselectlistbox .wj-listbox .wj-listbox-item:last-child {
  margin-bottom: 8px;
}

.wj-multiselectlistbox .wj-listbox .wj-listbox-item:hover {
  background: #EEEAF8 !important;
  transition-duration: 0 !important;
  cursor: pointer !important;
}

.wj-multiselectlistbox .wj-listbox .wj-listbox-item label {
  font-size: 13px;
  color: #000000;
  position: relative;
}

.wj-multiselectlistbox .wj-listbox .wj-listbox-item label input[type=checkbox] {
  display: none;
}

.wj-multiselectlistbox .wj-listbox .wj-listbox-item label span {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0px;
  left: -24px;
  border-radius: 2px;
  border: 1px solid #B9BCBB;
  background: #FFFFFF;
}

.wj-multiselectlistbox .wj-listbox .wj-listbox-item.wj-state-selected,
.wj-multiselectlistbox .wj-listbox .wj-listbox-item.wj-state-checked {
  background: none !important;
}

.wj-multiselectlistbox .wj-listbox .wj-listbox-item.wj-state-selected:hover,
.wj-multiselectlistbox .wj-listbox .wj-listbox-item.wj-state-checked:hover {
  background: #EEEAF8 !important;
  transition-duration: 0 !important;
  cursor: pointer !important;
}

.wj-multiselectlistbox .wj-listbox .wj-listbox-item.wj-state-selected input[type=checkbox]:checked+span,
.wj-multiselectlistbox .wj-listbox .wj-listbox-item.wj-state-checked input[type=checkbox]:checked+span {
  border: 1px solid #554596;
  background: #554596;
}

.wj-multiselectlistbox .wj-listbox .wj-listbox-item.wj-state-selected input[type=checkbox]:checked+span:before,
.wj-multiselectlistbox .wj-listbox .wj-listbox-item.wj-state-checked input[type=checkbox]:checked+span:before {
  content: "";
  clear: both;
  display: inline-block;
  width: 14px;
  height: 14px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.6343 5.23431C11.9467 4.9219 12.4533 4.9219 12.7657 5.23431C13.0781 5.54673 13.0781 6.05327 12.7657 6.36568L7.4217 11.7097C6.81646 12.3149 5.8352 12.315 5.22986 11.7099L2.23441 8.71548C1.92194 8.40311 1.92185 7.89658 2.23422 7.58411C2.54658 7.27163 3.05311 7.27154 3.36559 7.58391L6.32567 10.543L11.6343 5.23431Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: 14px 14px;
  background-repeat: no-repeat;
  background-position: 0px -1px;
}

.wj-multiselectlistbox .wj-listbox div[wj-part=select-all].wj-listbox-item {
  background: none !important;
  position: relative;
  margin-top: 8px;
}

.wj-multiselectlistbox .wj-listbox div[wj-part=select-all].wj-listbox-item:hover {
  background: #EEEAF8 !important;
  transition-duration: 0 !important;
  cursor: pointer !important;
}

.wj-multiselectlistbox .wj-listbox div[wj-part=select-all].wj-listbox-item label {
  cursor: pointer;
}

.wj-multiselectlistbox .wj-listbox div[wj-part=select-all].wj-listbox-item label span {
  display: inline-block;
  padding-left: 24px;
  width: 100% !important;
  border: none !important;
  background: none !important;
}

.wj-multiselectlistbox .wj-listbox div[wj-part=select-all].wj-listbox-item:before {
  content: "";
  clear: both;
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 2px;
  border: 1px solid #B9BCBB;
  background: #FFFFFF;
}

.wj-multiselectlistbox .wj-listbox div[wj-part=select-all].wj-listbox-item:has(input:checked) {
  background: #EEEAF8 !important;
}

.wj-multiselectlistbox .wj-listbox div[wj-part=select-all].wj-listbox-item:has(input:checked):before {
  content: "";
  clear: both;
  display: inline-block;
  border: 1px solid #554596;
  background-color: #554596;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 2px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.6343 5.23431C11.9467 4.9219 12.4533 4.9219 12.7657 5.23431C13.0781 5.54673 13.0781 6.05327 12.7657 6.36568L7.4217 11.7097C6.81646 12.3149 5.8352 12.315 5.22986 11.7099L2.23441 8.71548C1.92194 8.40311 1.92185 7.89658 2.23422 7.58411C2.54658 7.27163 3.05311 7.27154 3.36559 7.58391L6.32567 10.543L11.6343 5.23431Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: 14px 14px;
  background-repeat: no-repeat;
  background-position: 0px -1px;
}

.wj-control.wj-content.wj-popup.wj-state-focus.wj-state-focused {
  width: 500px;
  height: 350px;
}

.wj-control.wj-content.wj-popup.wj-state-focus.wj-state-focused>div:first-child {
  margin: 0 !important;
  height: calc(100% - 46px);
}

.wj-control.wj-content.wj-popup.wj-state-focus.wj-state-focused>div:first-child>div {
  height: 100%;
}

.wj-control.wj-content.wj-popup.wj-state-focus.wj-state-focused>div:last-child {
  margin: 4px 0 0 0 !important;
}

.wj-popup.wj-state-focused {
  padding: 8px;
  border-radius: 2px;
  width: auto;
  height: auto;
}

.wj-popup.wj-state-focused.sm {
  width: 200px !important;
  height: 200px !important;
}

.wj-popup.wj-state-focused.sm div:not(.textarea_btn) {
  width: 100%;
  height: 100%;
}

.wj-popup.wj-state-focused.sm textarea {
  width: 100%;
  height: 100%;
}

.wj-popup.wj-state-focused.md {
  width: 300px !important;
  height: 300px !important;
}

.wj-popup.wj-state-focused.md div:not(.textarea_btn) {
  width: 100%;
  height: 100%;
}

.wj-popup.wj-state-focused.md textarea {
  width: 100%;
  height: 100%;
}

.wj-popup.wj-state-focused.lg {
  width: 400px !important;
  height: 400px !important;
}

.wj-popup.wj-state-focused.lg div:not(.textarea_btn) {
  width: 100%;
  height: 100%;
}

.wj-popup.wj-state-focused.lg textarea {
  width: 100%;
  height: 100%;
}

.wj-popup.wj-state-focused>div {
  position: relative;
}

.wj-popup.wj-state-focused>div:has(textarea:focus) {
  border: 1px solid #B9BCBB;
}

.wj-popup.wj-state-focused p {
  position: absolute;
  bottom: 0;
  left: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #5B5C5B;
  height: 24px;
}

.wj-popup.wj-state-focused div:has(button) {
  display: flex !important;
  align-items: center;
  justify-content: right;
  margin-top: 4px;
}

.wj-flexgrid {
  border: 1px solid #DDE0DF !important;
  color: #000000;
  font-size: 13px;
}

.wj-flexgrid:focus,
.wj-flexgrid:active {
  border: 1px solid #DDE0DF !important;
  outline: none !important;
}

.wj-flexgrid:has(.wj-cell:focus),
.wj-flexgrid:has(.wj-cell:active) {
  border: 1px solid #DDE0DF !important;
  outline: none !important;
}

.wj-flexgrid .wj-cell {
  padding: 8px 8px 7px;
  min-height: 36px;
}

.wj-flexgrid .wj-cell:focus,
.wj-flexgrid .wj-cell:active {
  /* border: none !important; */
  outline: none !important;
}

.wj-flexgrid.wj-multirow .wj-cell {
  padding: 7px 8px;
}

.wj-flexgrid.wj-multirow .wj-cell:focus,
.wj-flexgrid.wj-multirow .wj-cell:active {
  border: none !important;
  outline: none !important;
}

.wj-flexgrid .wj-marquee {
  display: none;
}

.wj-flexgrid div[wj-part=ch] div[wj-part=chcells].wj-colheaders .wj-row .wj-cell {
  padding-top: 8px;
  border-right: 1px solid #DDE0DF;
  border-bottom: 1px solid #DDE0DF;
  background: #F1F4F3;
  text-align: center !important;
  font-weight: 400 !important;
}

.wj-flexgrid div[wj-part=ch] div[wj-part=chcells].wj-colheaders .wj-row .wj-cell.wj-header-alt {
  border-top: none;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-row:hover .wj-cell:not(.wj-state-selected):not(.wj-state-multi-selected) {
  background: #F1F4F3 !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-row:focus .wj-cell:not(.wj-state-selected):not(.wj-state-multi-selected) {
  background: #EEEAF8;
  border: 1px solid #A897DF;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-row:nth-child(odd) .wj-cell[aria-readonly=true] {
  background: #F7F9F8;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-row:nth-child(even) .wj-cell[aria-readonly=true] {
  background: #FFFFFF;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-row[aria-level="1"] .wj-cell {
  background: #D8D9F9 !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-row[aria-level="1"]:hover {
  background: #B4B5F3 !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-row[aria-level="2"] .wj-cell {
  background: #E3F0FF !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-row[aria-level="2"]:hover {
  background: #C8E0FF !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-row[aria-level="3"] .wj-cell {
  background: #F6FCD8 !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-row[aria-level="3"]:hover {
  background: #ECFAB1 !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-row[aria-level="4"] .wj-cell {
  background: #FDD4DA !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-row[aria-level="4"]:hover {
  background: #FCA9BF !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell {
  border: 1px solid #F1F4F3;
  background: #F1FDF7;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell a:link,
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell a:visited,
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell a:active,
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell a:hover {
  text-decoration: underline !important;
  color: #2D9BB2 !important;
  text-underline-position: under;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.wj-state-active,
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.wj-state-active:hover {
  color: #000000;
  background: #EEEAF8 !important;
  border: 1px solid #A897DF !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.wj-state-multi-selected,
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.wj-state-multi-selected:hover {
  color: #000000;
  background: #EEEAF8 !important;
}

/* .wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.subTotal:not(.wj-state-multi-selected),
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.subTotal:not(.wj-state-multi-selected):hover {
  background: #ECFCF8 !important;
} */

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.rowTotal:not(.wj-state-multi-selected),
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.rowTotal:not(.wj-state-multi-selected):hover {
  background: #D9FCF4 !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.sumTotal:not(.wj-state-multi-selected),
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.sumTotal:not(.wj-state-multi-selected):hover {
  background: #b4faef !important;
}

/* 프로젝트관리 Dashboard 적기완료 */
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.clpt:not(.wj-state-multi-selected),
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.clpt:not(.wj-state-multi-selected):hover {
  background: #DDE0DF !important;
}

/* 프로젝트관리 Dashboard 지연완료 */
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.dlyCplt:not(.wj-state-multi-selected),
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.dlyCplt:not(.wj-state-multi-selected):hover {
  background: #979998 !important;
}

/* 프로젝트관리 Dashboard 적기완료 */
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.obsr:not(.wj-state-multi-selected),
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.obsr:not(.wj-state-multi-selected):hover {
  background: #56D8AA !important;
}

/* 프로젝트관리 Dashboard 준수 */
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.dly:not(.wj-state-multi-selected),
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.dly:not(.wj-state-multi-selected):hover {
  background: #FDA293 !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.wj-group:not(.wj-align-center, .wj-align-right, .wj-alt) {
  text-align: left !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell label input[type=checkbox] {
  display: none;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell label span {
  display: inline-block;
  width: 16px;
  height: 16px;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell:before {
  content: "";
  clear: both;
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 4px;
  height: 4px;
  border-top: 2px solid #554596;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid #554596;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell[aria-readonly=true]:before {
  display: none;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoSelect {
  padding: 8px 8px 2px 8px;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell:has(span[class=cellAdd], span[class=cellDelete], span[class=cellModify]) {
  padding: 8px 0px 0px;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.wj-hasdropdown {
  border: 1px solid #A897DF;
  background: #EEEAF8 !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell .wj-input-group.wj-input-btn-visible {
  background: #EEEAF8;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell .wj-input-group.wj-input-btn-visible .wj-btn.wj-btn-default {
  border: none !important;
  background: #EEEAF8;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell .wj-input-group.wj-input-btn-visible .wj-btn.wj-btn-default:before {
  content: "";
  clear: both;
  display: inline-block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%) rotate(180deg);
  background: #000000;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 10 10' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.16673 6.59291C5.12245 6.64011 5.06239 6.66663 4.99976 6.66663C4.93714 6.66663 4.87708 6.64011 4.83279 6.59291L2.56891 4.17947C2.53599 4.14427 2.51358 4.09946 2.50451 4.05069C2.49544 4.00193 2.50012 3.95139 2.51795 3.90545C2.53578 3.85952 2.56597 3.82024 2.60471 3.79257C2.64345 3.76489 2.68901 3.75007 2.73564 3.74996L7.26341 3.74996C7.31018 3.74987 7.35592 3.76459 7.39484 3.79225C7.43375 3.81991 7.46409 3.85926 7.482 3.90532C7.49991 3.95138 7.50459 4.00207 7.49544 4.05097C7.48629 4.09987 7.46373 4.14477 7.43062 4.17998L5.16673 6.59291Z' fill=''/%3E%3C/svg%3E%0A");
  mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 10 10' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.16673 6.59291C5.12245 6.64011 5.06239 6.66663 4.99976 6.66663C4.93714 6.66663 4.87708 6.64011 4.83279 6.59291L2.56891 4.17947C2.53599 4.14427 2.51358 4.09946 2.50451 4.05069C2.49544 4.00193 2.50012 3.95139 2.51795 3.90545C2.53578 3.85952 2.56597 3.82024 2.60471 3.79257C2.64345 3.76489 2.68901 3.75007 2.73564 3.74996L7.26341 3.74996C7.31018 3.74987 7.35592 3.76459 7.39484 3.79225C7.43375 3.81991 7.46409 3.85926 7.482 3.90532C7.49991 3.95138 7.50459 4.00207 7.49544 4.05097C7.48629 4.09987 7.46373 4.14477 7.43062 4.17998L5.16673 6.59291Z' fill=''/%3E%3C/svg%3E%0A");
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell .wj-input-group.wj-input-btn-visible .wj-btn.wj-btn-default span {
  display: none !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoDate {
  line-height: 1.3;
  padding: 8px 8px 2px 32px;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoDate .wj-btn-glyph.wj-elem-dropdown {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px !important;
  height: 16px !important;
  margin-right: 4px;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoDate .wj-btn-glyph.wj-elem-dropdown:before {
  width: 16px !important;
  height: 16px !important;
  background: #5B5C5B;
  mask-size: 16px 16px;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.4 14.6L15.893 14.6C15.5617 14.6 15.293 14.3314 15.293 14C15.293 13.6687 15.5617 13.4 15.893 13.4L17.4 13.4C17.7314 13.4 18 13.6687 18 14C18 14.3314 17.7314 14.6 17.4 14.6Z' fill=''/%3E%3Cpath d='M12.8791 14.6L11.1209 14.6C10.7896 14.6 10.5209 14.3314 10.5209 14C10.5209 13.6687 10.7896 13.4 11.1209 13.4L12.8791 13.4C13.2104 13.4 13.4791 13.6687 13.4791 14C13.4791 14.3314 13.2104 14.6 12.8791 14.6Z' fill=''/%3E%3Cpath d='M8.10698 14.6L6.6 14.6C6.26863 14.6 6 14.3314 6 14C6 13.6687 6.26863 13.4 6.6 13.4L8.10698 13.4C8.43835 13.4 8.70698 13.6687 8.70698 14C8.70698 14.3314 8.43835 14.6 8.10698 14.6Z' fill=''/%3E%3Cpath d='M11.1209 17.6L12.8791 17.6C13.2104 17.6 13.4791 17.3314 13.4791 17C13.4791 16.6687 13.2104 16.4 12.8791 16.4L11.1209 16.4C10.7896 16.4 10.5209 16.6687 10.5209 17C10.5209 17.3314 10.7896 17.6 11.1209 17.6Z' fill=''/%3E%3Cpath d='M6.6 17.6L8.10698 17.6C8.43835 17.6 8.70698 17.3314 8.70698 17C8.70698 16.6687 8.43835 16.4 8.10698 16.4L6.6 16.4C6.26863 16.4 6 16.6687 6 17C6 17.3314 6.26863 17.6 6.6 17.6Z' fill=''/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.5999 2.60012C8.5999 2.26875 8.33127 2.00012 7.9999 2.00012C7.66853 2.00012 7.3999 2.26875 7.3999 2.60012V4.00012H6C4.34315 4.00012 3 5.34327 3 7.00012V19.0001C3 20.657 4.34315 22.0001 6 22.0001H18C19.6569 22.0001 21 20.657 21 19.0001V7.00012C21 5.34327 19.6569 4.00012 18 4.00012H16.5999V2.60012C16.5999 2.26875 16.3313 2.00012 15.9999 2.00012C15.6685 2.00012 15.3999 2.26875 15.3999 2.60012V4.00012H8.5999L8.5999 2.60012ZM18 5.20012H6C5.00589 5.20012 4.2 6.00601 4.2 7.00012V9.00012L19.8 9.00012V7.00012C19.8 6.00601 18.9941 5.20012 18 5.20012ZM4.2 19.0001V10.2001L19.8 10.2001V19.0001C19.8 19.9942 18.9941 20.8001 18 20.8001H6C5.00589 20.8001 4.2 19.9942 4.2 19.0001Z' fill=''/%3E%3C/svg%3E%0A");
  mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.4 14.6L15.893 14.6C15.5617 14.6 15.293 14.3314 15.293 14C15.293 13.6687 15.5617 13.4 15.893 13.4L17.4 13.4C17.7314 13.4 18 13.6687 18 14C18 14.3314 17.7314 14.6 17.4 14.6Z' fill=''/%3E%3Cpath d='M12.8791 14.6L11.1209 14.6C10.7896 14.6 10.5209 14.3314 10.5209 14C10.5209 13.6687 10.7896 13.4 11.1209 13.4L12.8791 13.4C13.2104 13.4 13.4791 13.6687 13.4791 14C13.4791 14.3314 13.2104 14.6 12.8791 14.6Z' fill=''/%3E%3Cpath d='M8.10698 14.6L6.6 14.6C6.26863 14.6 6 14.3314 6 14C6 13.6687 6.26863 13.4 6.6 13.4L8.10698 13.4C8.43835 13.4 8.70698 13.6687 8.70698 14C8.70698 14.3314 8.43835 14.6 8.10698 14.6Z' fill=''/%3E%3Cpath d='M11.1209 17.6L12.8791 17.6C13.2104 17.6 13.4791 17.3314 13.4791 17C13.4791 16.6687 13.2104 16.4 12.8791 16.4L11.1209 16.4C10.7896 16.4 10.5209 16.6687 10.5209 17C10.5209 17.3314 10.7896 17.6 11.1209 17.6Z' fill=''/%3E%3Cpath d='M6.6 17.6L8.10698 17.6C8.43835 17.6 8.70698 17.3314 8.70698 17C8.70698 16.6687 8.43835 16.4 8.10698 16.4L6.6 16.4C6.26863 16.4 6 16.6687 6 17C6 17.3314 6.26863 17.6 6.6 17.6Z' fill=''/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.5999 2.60012C8.5999 2.26875 8.33127 2.00012 7.9999 2.00012C7.66853 2.00012 7.3999 2.26875 7.3999 2.60012V4.00012H6C4.34315 4.00012 3 5.34327 3 7.00012V19.0001C3 20.657 4.34315 22.0001 6 22.0001H18C19.6569 22.0001 21 20.657 21 19.0001V7.00012C21 5.34327 19.6569 4.00012 18 4.00012H16.5999V2.60012C16.5999 2.26875 16.3313 2.00012 15.9999 2.00012C15.6685 2.00012 15.3999 2.26875 15.3999 2.60012V4.00012H8.5999L8.5999 2.60012ZM18 5.20012H6C5.00589 5.20012 4.2 6.00601 4.2 7.00012V9.00012L19.8 9.00012V7.00012C19.8 6.00601 18.9941 5.20012 18 5.20012ZM4.2 19.0001V10.2001L19.8 10.2001V19.0001C19.8 19.9942 18.9941 20.8001 18 20.8001H6C5.00589 20.8001 4.2 19.9942 4.2 19.0001Z' fill=''/%3E%3C/svg%3E%0A");
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoDate .wj-state-focused {
  border: 1px solid #A897DF;
  background: #EEEAF8 !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoDate .wj-state-focused .wj-input-group-btn {
  display: none;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoDate .wj-state-focused .wj-input-group-btn .wj-btn-default {
  border: none !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoFind {
  padding: 8px 8px 2px 32px;
  text-align: left;
  /* &[aria-selected="true"],[aria-selected="true"]:hover {
    border:1px solid $table-border-cell-focus;
    background:$table-bg-cell-selected !important;
  } */
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoFind a:link,
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoFind a:hover,
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoFind a:visited,
.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoFind a:active {
  text-decoration: none !important;
  color: #000000 !important;
  pointer-events: none;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoFind input {
  border: none !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoFind input:focus {
  border-radius: 0px !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoFind button {
  width: 16px;
  min-width: 16px;
  height: 16px;
  padding: 0px;
  border-radius: 0px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  background: #5B5C5B;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 16 16' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.7141 11.2801C9.71951 12.1439 8.42084 12.6668 7.00004 12.6668C3.87043 12.6668 1.33337 10.1298 1.33337 7.00016C1.33337 3.87055 3.87043 1.3335 7.00004 1.3335C10.1297 1.3335 12.6667 3.87055 12.6667 7.00016C12.6667 8.42105 12.1438 9.71979 11.2798 10.7144L14.5494 13.984C14.7056 14.1402 14.7056 14.3935 14.5494 14.5497C14.3932 14.7059 14.1399 14.7059 13.9837 14.5497L10.7141 11.2801ZM11.8667 7.00016C11.8667 9.68795 9.68783 11.8668 7.00004 11.8668C4.31225 11.8668 2.13337 9.68795 2.13337 7.00016C2.13337 4.31238 4.31225 2.1335 7.00004 2.1335C9.68783 2.1335 11.8667 4.31238 11.8667 7.00016Z' fill=''/%3E%3C/svg%3E%0A");
  mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 16 16' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.7141 11.2801C9.71951 12.1439 8.42084 12.6668 7.00004 12.6668C3.87043 12.6668 1.33337 10.1298 1.33337 7.00016C1.33337 3.87055 3.87043 1.3335 7.00004 1.3335C10.1297 1.3335 12.6667 3.87055 12.6667 7.00016C12.6667 8.42105 12.1438 9.71979 11.2798 10.7144L14.5494 13.984C14.7056 14.1402 14.7056 14.3935 14.5494 14.5497C14.3932 14.7059 14.1399 14.7059 13.9837 14.5497L10.7141 11.2801ZM11.8667 7.00016C11.8667 9.68795 9.68783 11.8668 7.00004 11.8668C4.31225 11.8668 2.13337 9.68795 2.13337 7.00016C2.13337 4.31238 4.31225 2.1335 7.00004 2.1335C9.68783 2.1335 11.8667 4.31238 11.8667 7.00016Z' fill=''/%3E%3C/svg%3E%0A");
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoPopup {
  padding-right: 32px;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoPopup>button {
  width: 16px;
  min-width: 16px;
  height: 16px;
  padding: 0px;
  border-radius: 0px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: #5B5C5B;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 16 16' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.86654 1.73337C9.86654 1.51246 10.0456 1.33337 10.2665 1.33337H13.2665C14.0397 1.33337 14.6665 1.96018 14.6665 2.73337V5.73337C14.6665 5.95429 14.4874 6.13337 14.2665 6.13337C14.0456 6.13337 13.8665 5.95429 13.8665 5.73337V2.73337C13.8665 2.72217 13.8662 2.71103 13.8656 2.69997L8.28278 8.28282C8.12657 8.43903 7.8733 8.43903 7.71709 8.28282C7.56088 8.12661 7.56088 7.87334 7.71709 7.71713L13.2999 2.13429C13.2889 2.13368 13.2777 2.13337 13.2665 2.13337H10.2665C10.0456 2.13337 9.86654 1.95429 9.86654 1.73337Z' fill='%235B5C5B'/%3E%3Cpath d='M4 2.80004C3.33726 2.80004 2.8 3.3373 2.8 4.00004V12C2.8 12.6628 3.33726 13.2 4 13.2H12C12.6627 13.2 13.2 12.6628 13.2 12V8.66664C13.2 8.44573 13.3791 8.26664 13.6 8.26664C13.8209 8.26664 14 8.44573 14 8.66664V12C14 13.1046 13.1046 14 12 14H4C2.89543 14 2 13.1046 2 12V4.00004C2 2.89547 2.89543 2.00004 4 2.00004H7.33333C7.55425 2.00004 7.73333 2.17913 7.73333 2.40004C7.73333 2.62095 7.55425 2.80004 7.33333 2.80004H4Z' fill='%235B5C5B'/%3E%3C/svg%3E%0A");
  mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 16 16' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.86654 1.73337C9.86654 1.51246 10.0456 1.33337 10.2665 1.33337H13.2665C14.0397 1.33337 14.6665 1.96018 14.6665 2.73337V5.73337C14.6665 5.95429 14.4874 6.13337 14.2665 6.13337C14.0456 6.13337 13.8665 5.95429 13.8665 5.73337V2.73337C13.8665 2.72217 13.8662 2.71103 13.8656 2.69997L8.28278 8.28282C8.12657 8.43903 7.8733 8.43903 7.71709 8.28282C7.56088 8.12661 7.56088 7.87334 7.71709 7.71713L13.2999 2.13429C13.2889 2.13368 13.2777 2.13337 13.2665 2.13337H10.2665C10.0456 2.13337 9.86654 1.95429 9.86654 1.73337Z' fill='%235B5C5B'/%3E%3Cpath d='M4 2.80004C3.33726 2.80004 2.8 3.3373 2.8 4.00004V12C2.8 12.6628 3.33726 13.2 4 13.2H12C12.6627 13.2 13.2 12.6628 13.2 12V8.66664C13.2 8.44573 13.3791 8.26664 13.6 8.26664C13.8209 8.26664 14 8.44573 14 8.66664V12C14 13.1046 13.1046 14 12 14H4C2.89543 14 2 13.1046 2 12V4.00004C2 2.89547 2.89543 2.00004 4 2.00004H7.33333C7.55425 2.00004 7.73333 2.17913 7.73333 2.40004C7.73333 2.62095 7.55425 2.80004 7.33333 2.80004H4Z' fill='%235B5C5B'/%3E%3C/svg%3E%0A");
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoRelay button {
  width: 16px;
  min-width: 16px;
  height: 16px;
  padding: 0px;
  border-radius: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #5B5C5B;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 17 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.1943 0.727051H4.581C2.53397 0.727051 0.769287 2.38875 0.769287 4.43973V15.1298C0.769287 17.2963 2.41765 18.9998 4.581 18.9998H12.5225C14.5705 18.9998 16.2308 17.1808 16.2308 15.1298V5.97996L11.1943 0.727051Z' stroke='%23130F26' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.6084 0.727051V3.70816C10.6084 5.16335 11.822 6.34391 13.3219 6.34698C14.7119 6.35006 16.1347 6.35108 16.2308 6.34493' stroke='%23130F26' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.9802 14.5526C8.11808 14.7002 8.30541 14.7832 8.50067 14.7832C8.69603 14.7832 8.88336 14.7002 9.02114 14.5525L11.8002 11.5743C12.0864 11.2677 12.0853 10.7716 11.7979 10.4664C11.5104 10.1612 11.0454 10.1622 10.7592 10.4689L9.23461 12.1027L9.23461 7.13308C9.23461 6.70041 8.90578 6.34964 8.50018 6.34964C8.09458 6.34964 7.76575 6.70041 7.76575 7.13308L7.76575 12.1022L6.24098 10.4688C5.95474 10.1622 5.4898 10.1612 5.20239 10.4665C4.91499 10.7717 4.91401 11.2678 5.20014 11.5744L7.9802 14.5526Z' fill='%23130F26'/%3E%3C/svg%3E%0A");
  mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 17 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.1943 0.727051H4.581C2.53397 0.727051 0.769287 2.38875 0.769287 4.43973V15.1298C0.769287 17.2963 2.41765 18.9998 4.581 18.9998H12.5225C14.5705 18.9998 16.2308 17.1808 16.2308 15.1298V5.97996L11.1943 0.727051Z' stroke='%23130F26' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.6084 0.727051V3.70816C10.6084 5.16335 11.822 6.34391 13.3219 6.34698C14.7119 6.35006 16.1347 6.35108 16.2308 6.34493' stroke='%23130F26' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.9802 14.5526C8.11808 14.7002 8.30541 14.7832 8.50067 14.7832C8.69603 14.7832 8.88336 14.7002 9.02114 14.5525L11.8002 11.5743C12.0864 11.2677 12.0853 10.7716 11.7979 10.4664C11.5104 10.1612 11.0454 10.1622 10.7592 10.4689L9.23461 12.1027L9.23461 7.13308C9.23461 6.70041 8.90578 6.34964 8.50018 6.34964C8.09458 6.34964 7.76575 6.70041 7.76575 7.13308L7.76575 12.1022L6.24098 10.4688C5.95474 10.1622 5.4898 10.1612 5.20239 10.4665C4.91499 10.7717 4.91401 11.2678 5.20014 11.5744L7.9802 14.5526Z' fill='%23130F26'/%3E%3C/svg%3E%0A");
  mask-size: 16px 16px;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoCellMove button {
  width: 16px;
  min-width: 16px;
  height: 16px;
  padding: 0px;
  border-radius: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #5B5C5B;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.5 10.4666C2.5 10.2457 2.67909 10.0666 2.9 10.0666H14.1C14.3209 10.0666 14.5 10.2457 14.5 10.4666C14.5 10.6875 14.3209 10.8666 14.1 10.8666H2.9C2.67909 10.8666 2.5 10.6875 2.5 10.4666Z' fill='%235B5C5B'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.5 5.53336C2.5 5.31245 2.67909 5.13336 2.9 5.13336H14.1C14.3209 5.13336 14.5 5.31245 14.5 5.53336C14.5 5.75428 14.3209 5.93336 14.1 5.93336H2.9C2.67909 5.93336 2.5 5.75428 2.5 5.53336Z' fill='%235B5C5B'/%3E%3C/svg%3E%0A");
  mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.5 10.4666C2.5 10.2457 2.67909 10.0666 2.9 10.0666H14.1C14.3209 10.0666 14.5 10.2457 14.5 10.4666C14.5 10.6875 14.3209 10.8666 14.1 10.8666H2.9C2.67909 10.8666 2.5 10.6875 2.5 10.4666Z' fill='%235B5C5B'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.5 5.53336C2.5 5.31245 2.67909 5.13336 2.9 5.13336H14.1C14.3209 5.13336 14.5 5.31245 14.5 5.53336C14.5 5.75428 14.3209 5.93336 14.1 5.93336H2.9C2.67909 5.93336 2.5 5.75428 2.5 5.53336Z' fill='%235B5C5B'/%3E%3C/svg%3E%0A");
  mask-size: 16px 16px;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoCopy button {
  width: 16px;
  min-width: 16px;
  height: 16px;
  padding: 0px;
  border-radius: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #5B5C5B;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.60071 12.6001C3.26895 12.6001 3 12.3315 3 12.0001C3 11.6687 3.26895 11.4001 3.60071 11.4001L19.5693 11.4001C19.5582 11.3877 19.5466 11.3755 19.5347 11.3636L16.0913 7.92424C15.8567 7.68992 15.8567 7.31003 16.0913 7.07571C16.3259 6.8414 16.7062 6.8414 16.9408 7.07571L20.3842 10.5151C21.2053 11.3352 21.2053 12.6648 20.3842 13.4849L16.9408 16.9242C16.7062 17.1586 16.3259 17.1586 16.0913 16.9242C15.8567 16.6899 15.8567 16.31 16.0913 16.0757L19.5347 12.6364C19.5465 12.6245 19.558 12.6124 19.569 12.6001L3.60071 12.6001Z' fill=''/%3E%3C/svg%3E%0A");
  mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.60071 12.6001C3.26895 12.6001 3 12.3315 3 12.0001C3 11.6687 3.26895 11.4001 3.60071 11.4001L19.5693 11.4001C19.5582 11.3877 19.5466 11.3755 19.5347 11.3636L16.0913 7.92424C15.8567 7.68992 15.8567 7.31003 16.0913 7.07571C16.3259 6.8414 16.7062 6.8414 16.9408 7.07571L20.3842 10.5151C21.2053 11.3352 21.2053 12.6648 20.3842 13.4849L16.9408 16.9242C16.7062 17.1586 16.3259 17.1586 16.0913 16.9242C15.8567 16.6899 15.8567 16.31 16.0913 16.0757L19.5347 12.6364C19.5465 12.6245 19.558 12.6124 19.569 12.6001L3.60071 12.6001Z' fill=''/%3E%3C/svg%3E%0A");
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoPlay button {
  width: 24px;
  min-width: 24px;
  height: 24px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid #DDE0DF;
  background: #FFFFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoPlay button:before {
  content: "";
  clear: both;
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #5B5C5B;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5487 7.73323C10.6243 7.80409 10.6667 7.90018 10.6667 8.00038C10.6667 8.10057 10.6243 8.19667 10.5487 8.26753L6.68724 11.8897C6.63091 11.9424 6.55921 11.9783 6.48119 11.9928C6.40317 12.0073 6.32231 11.9998 6.24881 11.9713C6.17531 11.9428 6.11247 11.8944 6.06819 11.8325C6.02392 11.7705 6.0002 11.6976 6.00002 11.623L6.00002 4.37854C5.99988 4.30371 6.02343 4.23052 6.06768 4.16826C6.11193 4.10599 6.1749 4.05746 6.24859 4.0288C6.32229 4.00015 6.4034 3.99266 6.48163 4.0073C6.55987 4.02193 6.63171 4.05803 6.68805 4.11101L10.5487 7.73323Z' fill='%235B5C5B'/%3E%3C/svg%3E%0A");
  mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5487 7.73323C10.6243 7.80409 10.6667 7.90018 10.6667 8.00038C10.6667 8.10057 10.6243 8.19667 10.5487 8.26753L6.68724 11.8897C6.63091 11.9424 6.55921 11.9783 6.48119 11.9928C6.40317 12.0073 6.32231 11.9998 6.24881 11.9713C6.17531 11.9428 6.11247 11.8944 6.06819 11.8325C6.02392 11.7705 6.0002 11.6976 6.00002 11.623L6.00002 4.37854C5.99988 4.30371 6.02343 4.23052 6.06768 4.16826C6.11193 4.10599 6.1749 4.05746 6.24859 4.0288C6.32229 4.00015 6.4034 3.99266 6.48163 4.0073C6.55987 4.02193 6.63171 4.05803 6.68805 4.11101L10.5487 7.73323Z' fill='%235B5C5B'/%3E%3C/svg%3E%0A");
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoPlay button.detail {
  width: 100%;
  padding-right: 24px;
  border: none;
  background: transparent;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoPlay button.detail:before {
  left: auto;
  right: 0;
  z-index: 2;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoPlay button.detail:after {
  display: inline-block;
  content: "";
  width: 24px;
  height: 24px;
  border: 1px solid #DDE0DF;
  position: absolute;
  top: 0;
  right: 4px;
  border-radius: 2px;
  background-color: #fff;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoButton button {
  border-radius: 2px;
  min-width: 0px;
  height: 28px;
  cursor: pointer;
  font-family: "Malgun Gothic", "Segoe UI", "MS YaHei", Sans-Serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  padding: 5px 8px;
  white-space: nowrap;
  background: #FFFFFF;
  border: 1px solid #DDE0DF;
  color: #5B5C5B;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoButton button:hover {
  background: #F1F4F3;
  border: 1px solid #979998;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoButton button:active {
  background: #DDE0DF;
  border: 1px solid #6E706F;
  color: #000000;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoButton button:disabled {
  opacity: 0.4;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoButton button:disabled:hover {
  pointer-events: none !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoCheck .checkbox {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoCheck .checkbox input[type=checkbox] {
  opacity: 0;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoCheck .checkbox input[type=checkbox]+label {
  width: 16px;
  height: 16px;
  border: 1px solid #B9BCBB;
  background: #FFFFFF;
  border-radius: 2px;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoCheck .checkbox input[type=checkbox]:hover+label {
  border: 1px solid #A897DF;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoCheck .checkbox input[type=checkbox]:checked+label {
  border: 1px solid #A897DF;
  background-color: #554596;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.6343 5.23431C11.9467 4.9219 12.4533 4.9219 12.7657 5.23431C13.0781 5.54673 13.0781 6.05327 12.7657 6.36568L7.4217 11.7097C6.81646 12.3149 5.8352 12.315 5.22986 11.7099L2.23441 8.71548C1.92194 8.40311 1.92185 7.89658 2.23422 7.58411C2.54658 7.27163 3.05311 7.27154 3.36559 7.58391L6.32567 10.543L11.6343 5.23431Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 2px;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoCheck .checkbox input[type=checkbox]:checked:hover+label {
  border: 1px solid #A897DF;
  background-color: #A897DF;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoCheck .checkbox input[type=checkbox]:disabled+label {
  opacity: 0.4;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoDot span {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoDot span.confirm {
  background: #00806A;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoDot span.warning {
  background: #FFC907;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoDot span.error {
  background: #F94B50;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoDot span.done {
  background: #979998;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoDot span.plan {
  border: 1px solid #979998;
  background: #FFFFFF;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoTag span {
  height: 20px;
  border-radius: 2px;
  font-size: 12px;
  padding: 0 3px;
  position: absolute;
  line-height: 1.5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoTag span.green {
  border: 1px solid #56D8AA;
  background: #DCFBEA;
  color: #01463A;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoTag span.purple {
  border: 1px solid #A897DF;
  background: #E6DEF9;
  color: #554596;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoTag span.yellow {
  border: 1px solid #FFE56A;
  background: #FFF8CD;
  color: #B78803;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoTag span.orange {
  border: 1px solid #FFCB7A;
  background: #FFCB7A;
  color: #B75511;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoTag span.red {
  border: 1px solid #FDA293;
  background: #FEE6DB;
  color: #B32542;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoTag span.grey {
  border: 1px solid #979998;
  background: #F1F4F3;
  color: #5B5C5B;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell.WijmoTag span.blue {
  border: 1px solid #ADCEFF;
  background: #E3F0FF;
  color: #567FDB;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell .cellAdd {
  display: block;
  margin: 0 auto;
  width: 14px;
  min-width: 14px;
  height: 14px;
  padding: 4px 0 0 0;
  border-radius: 0px;
  background: #1F7795;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.3999 20.4C11.3999 20.7314 11.6685 21 11.9999 21C12.3313 21 12.5999 20.7314 12.5999 20.4V12.5999H20.4C20.7314 12.5999 21 12.3313 21 11.9999C21 11.6685 20.7314 11.3999 20.4 11.3999H12.5999V3.6C12.5999 3.26863 12.3313 3 11.9999 3C11.6685 3 11.3999 3.26863 11.3999 3.6V11.3999H3.6C3.26863 11.3999 3 11.6685 3 11.9999C3 12.3313 3.26863 12.5999 3.6 12.5999H11.3999V20.4Z' fill='%235B5C5B'/%3E%3C/svg%3E%0A");
  mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.3999 20.4C11.3999 20.7314 11.6685 21 11.9999 21C12.3313 21 12.5999 20.7314 12.5999 20.4V12.5999H20.4C20.7314 12.5999 21 12.3313 21 11.9999C21 11.6685 20.7314 11.3999 20.4 11.3999H12.5999V3.6C12.5999 3.26863 12.3313 3 11.9999 3C11.6685 3 11.3999 3.26863 11.3999 3.6V11.3999H3.6C3.26863 11.3999 3 11.6685 3 11.9999C3 12.3313 3.26863 12.5999 3.6 12.5999H11.3999V20.4Z' fill='%235B5C5B'/%3E%3C/svg%3E%0A");
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell .cellDelete {
  display: block;
  width: 14px;
  min-width: 14px;
  height: 14px;
  padding: 0px;
  margin: 0 auto;
  border-radius: 0px;
  background: #B32542;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 20 20' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.3339 7.41691C8.61005 7.41691 8.8339 7.64077 8.8339 7.91691V14.1669C8.8339 14.4431 8.61005 14.6669 8.3339 14.6669C8.05776 14.6669 7.8339 14.4431 7.8339 14.1669L7.8339 7.91691C7.8339 7.64077 8.05776 7.41691 8.3339 7.41691Z' fill='%235B5C5B'/%3E%3Cpath d='M12.1672 7.91691C12.1672 7.64077 11.9434 7.41691 11.6672 7.41691C11.3911 7.41691 11.1672 7.64077 11.1672 7.91691V14.1669C11.1672 14.4431 11.3911 14.6669 11.6672 14.6669C11.9434 14.6669 12.1672 14.4431 12.1672 14.1669L12.1672 7.91691Z' fill='%235B5C5B'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.08398 2.91699C7.08398 2.22664 7.64363 1.66699 8.33398 1.66699H11.6673C12.3577 1.66699 12.9173 2.22664 12.9173 2.91699V4.00037L16.1673 4.00037C16.4435 4.00037 16.6673 4.22422 16.6673 4.50037C16.6673 4.77651 16.4435 5.00037 16.1673 5.00037H15.834V15.667C15.834 17.0477 14.7147 18.167 13.334 18.167H6.66732C5.28661 18.167 4.16732 17.0477 4.16732 15.667V5.00037H3.83398C3.55784 5.00037 3.33398 4.77651 3.33398 4.50037C3.33398 4.22422 3.55784 4.00037 3.83398 4.00037H7.08398V2.91699ZM8.33398 2.66699H11.6673C11.8054 2.66699 11.9173 2.77892 11.9173 2.91699V4.00033H8.08398V2.91699C8.08398 2.77892 8.19591 2.66699 8.33398 2.66699ZM5.16732 5.00037H14.834V15.667C14.834 16.4955 14.1624 17.167 13.334 17.167H6.66732C5.83889 17.167 5.16732 16.4955 5.16732 15.667V5.00037Z' fill=''/%3E%3C/svg%3E%0A");
  mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 20 20' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.3339 7.41691C8.61005 7.41691 8.8339 7.64077 8.8339 7.91691V14.1669C8.8339 14.4431 8.61005 14.6669 8.3339 14.6669C8.05776 14.6669 7.8339 14.4431 7.8339 14.1669L7.8339 7.91691C7.8339 7.64077 8.05776 7.41691 8.3339 7.41691Z' fill='%235B5C5B'/%3E%3Cpath d='M12.1672 7.91691C12.1672 7.64077 11.9434 7.41691 11.6672 7.41691C11.3911 7.41691 11.1672 7.64077 11.1672 7.91691V14.1669C11.1672 14.4431 11.3911 14.6669 11.6672 14.6669C11.9434 14.6669 12.1672 14.4431 12.1672 14.1669L12.1672 7.91691Z' fill='%235B5C5B'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.08398 2.91699C7.08398 2.22664 7.64363 1.66699 8.33398 1.66699H11.6673C12.3577 1.66699 12.9173 2.22664 12.9173 2.91699V4.00037L16.1673 4.00037C16.4435 4.00037 16.6673 4.22422 16.6673 4.50037C16.6673 4.77651 16.4435 5.00037 16.1673 5.00037H15.834V15.667C15.834 17.0477 14.7147 18.167 13.334 18.167H6.66732C5.28661 18.167 4.16732 17.0477 4.16732 15.667V5.00037H3.83398C3.55784 5.00037 3.33398 4.77651 3.33398 4.50037C3.33398 4.22422 3.55784 4.00037 3.83398 4.00037H7.08398V2.91699ZM8.33398 2.66699H11.6673C11.8054 2.66699 11.9173 2.77892 11.9173 2.91699V4.00033H8.08398V2.91699C8.08398 2.77892 8.19591 2.66699 8.33398 2.66699ZM5.16732 5.00037H14.834V15.667C14.834 16.4955 14.1624 17.167 13.334 17.167H6.66732C5.83889 17.167 5.16732 16.4955 5.16732 15.667V5.00037Z' fill=''/%3E%3C/svg%3E%0A");
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-cell .cellModify {
  display: block;
  width: 14px;
  min-width: 14px;
  height: 14px;
  padding: 0px;
  margin: 0 auto;
  border-radius: 0px;
  background: #B78803;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 20 20' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.3903 10.9139L12.5874 2.7168C12.8765 2.42774 13.3451 2.42773 13.6342 2.71678L16.284 5.36645C16.5731 5.65552 16.5731 6.12421 16.284 6.41329L8.08696 14.6104C7.98889 14.7085 7.86525 14.777 7.73012 14.8082L4.86181 15.4707C4.06219 15.6554 3.34548 14.9386 3.53013 14.139L4.19248 11.2708C4.22368 11.1357 4.29223 11.012 4.3903 10.9139ZM4.50449 14.364L5.15008 11.5684L13.1108 3.60761L15.3932 5.88988L7.43251 13.8506L4.63678 14.4963C4.55738 14.5147 4.48614 14.4435 4.50449 14.364Z' fill='%235B5C5B'/%3E%3Cpath d='M3.83398 16.4998C3.55784 16.4998 3.33398 16.7237 3.33398 16.9998C3.33398 17.276 3.55784 17.4998 3.83398 17.4998H16.1673C16.4435 17.4998 16.6673 17.276 16.6673 16.9998C16.6673 16.7237 16.4435 16.4998 16.1673 16.4998H3.83398Z' fill=''/%3E%3C/svg%3E%0A");
  mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 20 20' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.3903 10.9139L12.5874 2.7168C12.8765 2.42774 13.3451 2.42773 13.6342 2.71678L16.284 5.36645C16.5731 5.65552 16.5731 6.12421 16.284 6.41329L8.08696 14.6104C7.98889 14.7085 7.86525 14.777 7.73012 14.8082L4.86181 15.4707C4.06219 15.6554 3.34548 14.9386 3.53013 14.139L4.19248 11.2708C4.22368 11.1357 4.29223 11.012 4.3903 10.9139ZM4.50449 14.364L5.15008 11.5684L13.1108 3.60761L15.3932 5.88988L7.43251 13.8506L4.63678 14.4963C4.55738 14.5147 4.48614 14.4435 4.50449 14.364Z' fill='%235B5C5B'/%3E%3Cpath d='M3.83398 16.4998C3.55784 16.4998 3.33398 16.7237 3.33398 16.9998C3.33398 17.276 3.55784 17.4998 3.83398 17.4998H16.1673C16.4435 17.4998 16.6673 17.276 16.6673 16.9998C16.6673 16.7237 16.4435 16.4998 16.1673 16.4998H3.83398Z' fill=''/%3E%3C/svg%3E%0A");
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-state-selected {
  color: #000000;
  background: #EEEAF8 !important;
  border: 1px solid #A897DF;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-btn-glyph.wj-elem-dropdown {
  width: 10px !important;
  height: 10px !important;
  padding: 0px !important;
  min-width: 0px !important;
  border-radius: 0px !important;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  opacity: 1 !important;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-btn-glyph.wj-elem-dropdown:before {
  content: "";
  clear: both;
  display: inline-block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  right: 0;
  background: #000000;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 10 10' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.16673 6.59291C5.12245 6.64011 5.06239 6.66663 4.99976 6.66663C4.93714 6.66663 4.87708 6.64011 4.83279 6.59291L2.56891 4.17947C2.53599 4.14427 2.51358 4.09946 2.50451 4.05069C2.49544 4.00193 2.50012 3.95139 2.51795 3.90545C2.53578 3.85952 2.56597 3.82024 2.60471 3.79257C2.64345 3.76489 2.68901 3.75007 2.73564 3.74996L7.26341 3.74996C7.31018 3.74987 7.35592 3.76459 7.39484 3.79225C7.43375 3.81991 7.46409 3.85926 7.482 3.90532C7.49991 3.95138 7.50459 4.00207 7.49544 4.05097C7.48629 4.09987 7.46373 4.14477 7.43062 4.17998L5.16673 6.59291Z' fill=''/%3E%3C/svg%3E%0A");
  mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 10 10' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.16673 6.59291C5.12245 6.64011 5.06239 6.66663 4.99976 6.66663C4.93714 6.66663 4.87708 6.64011 4.83279 6.59291L2.56891 4.17947C2.53599 4.14427 2.51358 4.09946 2.50451 4.05069C2.49544 4.00193 2.50012 3.95139 2.51795 3.90545C2.53578 3.85952 2.56597 3.82024 2.60471 3.79257C2.64345 3.76489 2.68901 3.75007 2.73564 3.74996L7.26341 3.74996C7.31018 3.74987 7.35592 3.76459 7.39484 3.79225C7.43375 3.81991 7.46409 3.85926 7.482 3.90532C7.49991 3.95138 7.50459 4.00207 7.49544 4.05097C7.48629 4.09987 7.46373 4.14477 7.43062 4.17998L5.16673 6.59291Z' fill=''/%3E%3C/svg%3E%0A");
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-btn-glyph.wj-elem-dropdown span {
  display: none;
}

.wj-flexgrid div[wj-part=root] div[wj-part=cells] .wj-btn-glyph.wj-elem-dropdown[aria-expanded=true]:before {
  transform: rotate(180deg);
}

.wj-flexgrid div[wj-part=rh] div[wj-part=rhcells].wj-rowheaders .wj-row .wj-header {
  padding: 8px 0px 0px;
}

.wj-flexgrid div[wj-part=rh] div[wj-part=rhcells].wj-rowheaders .wj-row .wj-header input[type=checkbox] {
  opacity: 0;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.wj-flexgrid div[wj-part=rh] div[wj-part=rhcells].wj-rowheaders .wj-row .wj-header input[type=checkbox]+span {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #B9BCBB;
  background: #FFFFFF;
  border-radius: 2px;
}

.wj-flexgrid div[wj-part=rh] div[wj-part=rhcells].wj-rowheaders .wj-row .wj-header input[type=checkbox]:checked+span {
  display: inline-block;
  border: 1px solid #A897DF;
  background-color: #554596;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.6343 5.23431C11.9467 4.9219 12.4533 4.9219 12.7657 5.23431C13.0781 5.54673 13.0781 6.05327 12.7657 6.36568L7.4217 11.7097C6.81646 12.3149 5.8352 12.315 5.22986 11.7099L2.23441 8.71548C1.92194 8.40311 1.92185 7.89658 2.23422 7.58411C2.54658 7.27163 3.05311 7.27154 3.36559 7.58391L6.32567 10.543L11.6343 5.23431Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 2px;
}

.wj-flexgrid div[wj-part=rh] div[wj-part=rhcells].wj-rowheaders .wj-row .wj-header input[type=checkbox]:checked:hover+span {
  border: 1px solid #A897DF;
  background-color: #A897DF;
}

.wj-flexgrid div[wj-part=rh] div[wj-part=rhcells].wj-rowheaders .wj-row .wj-header:has(input[type=checkbox]:checked) {
  background: #EEEAF8 !important;
}

.wj-flexgrid div[wj-part=rh] div[wj-part=rhcells].wj-rowheaders .wj-row .wj-header input[type=checkbox]:indeterminate+span {
  display: inline-block;
  border: 1px solid #A897DF;
  background-color: #554596;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='4' y='7' width='8' height='2' rx='1' fill='white'/%3E%3C/svg%3E%0A");
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 2px;
}

.wj-flexgrid div[wj-part=rh] div[wj-part=rhcells].wj-rowheaders .wj-row .wj-header input[type=checkbox]:indeterminate:hover+span {
  border: 1px solid #A897DF;
  background-color: #A897DF;
}

.wj-flexgrid div[wj-part=rh] .wj-cell {
  border: 1px solid #F1F4F3;
  background: #FFFFFF;
}

.wj-flexgrid div[wj-part=rh] .wj-row:nth-child(even) .wj-cell {
  background: #F7F9F8;
}

.wj-flexgrid div[wj-part=tl] .wj-cell {
  border: 1px solid #DDE0DF;
  background: #F1F4F3;
}

.wj-flexgrid div[wj-part=tl] div[wj-part=tlcells] .wj-header {
  border-left: none !important;
  border-top: none !important;
}

.wj-flexgrid div[wj-part=tl] div[wj-part=tlcells] .wj-header label {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wj-flexgrid div[wj-part=tl] div[wj-part=tlcells] .wj-header input[type=checkbox] {
  opacity: 0;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.wj-flexgrid div[wj-part=tl] div[wj-part=tlcells] .wj-header input[type=checkbox]+span {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #B9BCBB;
  background: #FFFFFF;
  border-radius: 2px;
}

.wj-flexgrid div[wj-part=tl] div[wj-part=tlcells] .wj-header input[type=checkbox]:checked+span {
  display: inline-block;
  border: 1px solid #A897DF;
  background-color: #554596;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.6343 5.23431C11.9467 4.9219 12.4533 4.9219 12.7657 5.23431C13.0781 5.54673 13.0781 6.05327 12.7657 6.36568L7.4217 11.7097C6.81646 12.3149 5.8352 12.315 5.22986 11.7099L2.23441 8.71548C1.92194 8.40311 1.92185 7.89658 2.23422 7.58411C2.54658 7.27163 3.05311 7.27154 3.36559 7.58391L6.32567 10.543L11.6343 5.23431Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 2px;
}

.wj-flexgrid div[wj-part=tl] div[wj-part=tlcells] .wj-header input[type=checkbox]:checked:hover+span {
  border: 1px solid #A897DF;
  background-color: #A897DF;
}

.wj-flexgrid div[wj-part=tl] div[wj-part=tlcells] .wj-header input[type=checkbox]:indeterminate+span {
  display: inline-block;
  border: 1px solid #A897DF;
  background-color: #554596;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='4' y='7' width='8' height='2' rx='1' fill='white'/%3E%3C/svg%3E%0A");
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 2px;
}

.wj-flexgrid div[wj-part=tl] div[wj-part=tlcells] .wj-header input[type=checkbox]:indeterminate:hover+span {
  border: 1px solid #A897DF;
  background-color: #A897DF;
}

.wj-flexgrid .wj-tooltip {
  background: #3C3E3D;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 1.5;
  white-space: nowrap;
  padding: 12px;
  max-width: 500px;
  border-radius: 2px;
}

.wj-flexgrid .wj-colheaders .wj-header.wj-colgroup {
  justify-content: center !important;
}

.transposed-header {
  padding-top: 8px;
  border-right: 1px solid #DDE0DF !important;
  border-bottom: 1px solid #DDE0DF !important;
  background: #F1F4F3 !important;
  text-align: center !important;
  font-weight: 400 !important;
}

.wj-header.dot:before {
  display: inline-block;
  content: "";
  position: relative;
  top: -8px;
  left: -2px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: #F94B50;
}

.wj-flexgrid .wj-glyph-plus,
.wj-flexgrid .wj-glyph-minus {
  border-top: 2px solid;
  width: 12px;
  top: -4px;
}

.wj-flexgrid .wj-glyph-plus:after {
  position: absolute;
  box-sizing: border-box;
  border-left: 2px solid;
  width: 2px;
  height: 12px;
  left: 5px;
  top: -7px;
  content: " ";
}