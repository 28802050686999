@charset "UTF-8";
/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
.wj-glyph, .wj-glyph-filter, .wj-glyph-up-right, .wj-glyph-up-left, .wj-glyph-up, .wj-glyph-step-forward, .wj-glyph-step-backward, .wj-glyph-square, .wj-glyph-right, .wj-glyph-pencil, .wj-glyph-plus, .wj-glyph-minus, .wj-glyph-pin, .wj-glyph-measure, .wj-glyph-left, .wj-glyph-file, .wj-glyph-drag, .wj-glyph-down-right, .wj-glyph-down-left, .wj-glyph-down, .wj-glyph-dimension, .wj-glyph-diamond, .wj-glyph-clock, .wj-glyph-clipboard, .wj-glyph-circle, .wj-glyph-check, .wj-glyph-calendar, .wj-glyph-asterisk {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  opacity: 0.75;
  white-space: normal;
  cursor: pointer;
}

.wj-glyph-asterisk:after {
  content: "✱︎";
}

.wj-glyph-calendar {
  width: 1em;
  height: 1em;
  border: 0.1em solid;
  border-top: 0.3em solid;
  top: 0.15em;
}
.wj-glyph-calendar:after {
  position: absolute;
  left: 0.3em;
  top: 0.1em;
  width: 0.2em;
  height: 0.4em;
  border-right: 0.1em solid;
  border-top: 0.07em solid;
  opacity: 0.7;
  content: " ";
}

.wj-glyph-check:after {
  font-weight: bold;
  content: "✔︎";
}

.wj-glyph-circle {
  border: 0.3em solid;
  border-radius: 50%;
  top: -0.1em;
}

.wj-glyph-clipboard:after {
  content: "📋︎";
}

.wj-glyph-clock {
  width: 1em;
  height: 1em;
  border: 0.1em solid;
  border-radius: 50%;
  top: 0.15em;
}
.wj-glyph-clock:after {
  position: absolute;
  border-left: 0.1em solid;
  border-bottom: 0.1em solid;
  width: 0.25em;
  height: 0.3em;
  top: 0.2em;
  left: 0.35em;
  content: " ";
}

.wj-glyph-diamond:after {
  content: "◆︎";
}

.wj-glyph-dimension:after {
  content: "⟀︎";
}

.wj-glyph-down {
  border-top: 0.5em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
}

.wj-glyph-down-left {
  border-top: 0.65em solid transparent;
  border-left: 0.65em solid;
}

.wj-glyph-down-right {
  top: -2px;
  border-bottom: 0.65em solid;
  border-left: 0.65em solid transparent;
}

.wj-glyph-drag {
  width: 1em;
  height: 1em;
}
.wj-glyph-drag:after {
  position: absolute;
  top: 0.15em;
  left: 0.1em;
  width: 1em;
  height: 1em;
  background: radial-gradient(#000 1px, transparent 2px);
  background-size: 0.33em 0.33em;
  content: " ";
}

.wj-glyph-file {
  border-left: 0.7em solid;
  height: 0.85em;
}
.wj-glyph-file:after {
  position: absolute;
  transform: translateX(-100%);
  border-top: 0.3em solid transparent;
  border-left: 0.3em solid white;
  content: " ";
}

.wj-glyph-left {
  top: 1px;
  border-top: 0.4em solid transparent;
  border-right: 0.5em solid;
  border-bottom: 0.4em solid transparent;
}

.wj-glyph-measure:after {
  content: "📊︎";
}

.wj-glyph-pin {
  top: -0.1em;
  left: 0.1em;
  width: 0.4em;
  border-top: 0.6em solid;
}
.wj-glyph-pin:before {
  position: absolute;
  border-left: 0.7em solid;
  height: 0.1em;
  left: -0.15em;
  top: -0.1em;
  content: " ";
}
.wj-glyph-pin:after {
  position: absolute;
  border-left: 0.15em solid;
  height: 0.5em;
  left: 0.15em;
  top: -0.1em;
  content: " ";
}

.wj-glyph-plus, .wj-glyph-minus {
  border-top: 0.25em solid;
  width: 0.9em;
  top: -0.3em;
}

.wj-glyph-plus:after {
  position: absolute;
  box-sizing: border-box;
  border-left: 0.25em solid;
  width: 0.25em;
  height: 0.95em;
  left: 0.33em;
  top: -0.6em;
  content: " ";
}

.wj-glyph-pencil:after {
  content: "✎︎";
}

.wj-glyph-right {
  top: 1px;
  border-top: 0.4em solid transparent;
  border-bottom: 0.4em solid transparent;
  border-left: 0.5em solid;
}

.wj-glyph-square {
  border: 0.25em solid;
}

.wj-glyph-step-backward {
  border-top: 0.4em solid transparent;
  border-right: 0.5em solid;
  border-bottom: 0.4em solid transparent;
  top: 0.12em;
}
.wj-glyph-step-backward:after {
  position: absolute;
  border-left: 0.2em solid;
  height: 0.75em;
  transform: translate(-100%, -50%);
  content: " ";
}

[dir=rtl] .wj-glyph-step-backward:after {
  transform: translate(0, -50%);
}

.wj-glyph-step-forward {
  border-top: 0.4em solid transparent;
  border-bottom: 0.4em solid transparent;
  border-left: 0.5em solid;
}
.wj-glyph-step-forward:after {
  position: absolute;
  border-left: 0.2em solid;
  height: 0.75em;
  transform: translateY(-50%);
  content: " ";
}

[dir=rtl] .wj-glyph-step-forward:after {
  transform: translate(100%, -50%);
}

.wj-glyph-up {
  border-right: 0.4em solid transparent;
  border-bottom: 0.5em solid;
  border-left: 0.4em solid transparent;
}

.wj-glyph-up-left {
  border-bottom: 0.65em solid transparent;
  border-left: 0.65em solid;
}

.wj-glyph-up-right {
  border-top: 0.65em solid;
  border-left: 0.65em solid transparent;
}

.wj-glyph-filter {
  top: -0.1em;
  width: 0.5em;
  border-top: 0.5em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
}
.wj-glyph-filter:after {
  position: absolute;
  border-left: 0.25em solid;
  left: -0.125em;
  top: -0.2em;
  height: 0.4em;
  content: " ";
}

/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
.wj-control {
  display: block;
  box-sizing: border-box;
  color: black;
  background: white;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.wj-control * {
  box-sizing: inherit;
}
.wj-control [contenteditable] {
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
}
.wj-control:focus,
.wj-control *:not(.wj-tabpane *):focus,
.wj-control .wj-tabpane .wj-control *:focus,
.wj-control a:focus {
  outline: none;
}
.wj-control input::-ms-clear {
  display: none;
}
.wj-control input:-ms-input-placeholder {
  opacity: 0.7;
}
.wj-control input[type=number]::-webkit-inner-spin-button, .wj-control input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.wj-control input:invalid {
  box-shadow: none;
  outline: none;
}
.wj-control .wj-btn {
  background: #eee;
  cursor: pointer;
  color: black;
  display: inline-block;
  border-style: none;
  padding: 0 10px;
  text-align: center;
  min-width: 30px;
  white-space: nowrap;
}
.wj-control .wj-btn:focus, .wj-control .wj-btn:hover {
  background: #e1e1e1;
  transition-duration: 0.4s;
}
.wj-control .wj-btn.wj-state-active {
  background: #d5d5d5;
}
.wj-control .wj-btn[disabled] {
  cursor: default;
  pointer-events: none;
  opacity: 0.6;
}
.wj-control a.wj-btn,
.wj-control button.wj-btn:not(.wj-btn-default) {
  border-radius: 4px;
  padding: 6px 15px;
  text-decoration: none;
  font-weight: bold;
  font-size: 12px;
}

.wj-header {
  background: #eee;
  color: #444;
  font-weight: bold;
}

.wj-state-multi-selected {
  background: #80adbf;
  color: white;
}

.wj-state-selected,
.wj-state-last-selected {
  background: #0085c7;
  color: white;
}

.wj-state-disabled:not(.wj-popup) {
  cursor: default;
  pointer-events: none;
  opacity: 0.6;
}

.wj-content {
  display: inline-block;
  overflow: hidden;
}

.wj-tooltip {
  box-sizing: border-box;
  position: absolute;
  pointer-events: none;
  max-width: 400px;
  padding: 4px 6px 3px 6px;
  background: white;
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: 0 6px 13px rgba(0, 0, 0, 0.2);
  word-break: break-word;
  -ms-word-break: break-all;
}
.wj-tooltip.wj-error-tip {
  background: red;
  color: white;
  white-space: pre-line;
  font-weight: bold;
}

.wj-dropdown-panel {
  pointer-events: auto;
  box-shadow: 0 6px 13px rgba(0, 0, 0, 0.2);
}

/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
.wj-content.wj-dropdown, .wj-content.wj-inputnumber, .wj-content.wj-inputmask, .wj-content.wj-calendar-outer, .wj-content.wj-pager {
  vertical-align: middle;
  display: inline-table;
  border-collapse: separate;
}

.wj-control.wj-inputmask {
  display: inline-flex;
}
.wj-control.wj-inputmask .wj-input {
  width: 100%;
}
.wj-control.wj-inputmask .wj-input-group {
  display: flex;
}
.wj-control.wj-inputmask .wj-input-group:focus, .wj-control.wj-inputmask .wj-input-group:active {
  border: none !important;
  outline: none !important;
}

.wj-dropdown-panel {
  display: block;
  background: white;
  color: black;
}
.wj-dropdown-panel .wj-control {
  background: transparent;
  color: inherit;
}
.wj-dropdown-panel.wj-content > .wj-content {
  border: none;
}

.wj-control .wj-template {
  position: relative;
  display: table-cell;
}
.wj-control .wj-template,
.wj-control .wj-input {
  height: 100%;
}
.wj-control .wj-input-group {
  display: table;
  border-collapse: separate;
  width: 100%;
  height: 100%;
}
.wj-control .wj-input-group:focus, .wj-control .wj-input-group:active {
  border: none !important;
  outline: none !important;
}
.wj-control .wj-input-group .wj-input-group-btn {
  display: table-cell;
  width: 1%;
  height: 100%;
  min-width: 26px;
  white-space: nowrap;
  vertical-align: top;
}
.wj-control .wj-input-group .wj-input-group-btn:last-child:not(:first-child) > .wj-btn {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.wj-control .wj-input-group .wj-input-group-btn:first-child:not(:last-child) > .wj-btn {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.wj-control .wj-input-group .wj-form-control {
  display: table-cell;
  padding: 4px 8px;
  border: none;
  width: 100%;
  height: 100%;
  color: inherit;
  background: transparent;
  min-height: 2em;
}
.wj-control .wj-input-group .wj-form-control:focus, .wj-control .wj-input-group .wj-form-control:active {
  border: none !important;
  outline: none !important;
}
.wj-control .wj-input-group button, .wj-control .wj-input-group input {
  margin: 0;
}
.wj-control .wj-btn-group {
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
}
.wj-control .wj-btn-group > *:not(:first-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.wj-control .wj-btn-group > .wj-form-control {
  padding: 4px 6px 3px 6px;
  border: none;
}
.wj-control .wj-btn-group > .wj-form-control:not(:first-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.wj-control .wj-btn-group > .wj-btn,
.wj-control .wj-input-group-btn > .wj-btn {
  height: 100%;
  min-height: 2em;
  background: #eee;
}
.wj-control .wj-btn-group > .wj-btn:focus, .wj-control .wj-btn-group > .wj-btn:hover,
.wj-control .wj-input-group-btn > .wj-btn:focus,
.wj-control .wj-input-group-btn > .wj-btn:hover {
  background: #e1e1e1;
  transition-duration: 0.4s;
}
.wj-control .wj-btn-group > .wj-btn.wj-btn-default,
.wj-control .wj-input-group-btn > .wj-btn.wj-btn-default {
  color: black;
}
.wj-control .wj-btn-group > .wj-btn.wj-btn-default:enabled,
.wj-control .wj-input-group-btn > .wj-btn.wj-btn-default:enabled {
  background: transparent;
}
.wj-control .wj-btn-group > .wj-btn.wj-btn-default:focus, .wj-control .wj-btn-group > .wj-btn.wj-btn-default:hover,
.wj-control .wj-input-group-btn > .wj-btn.wj-btn-default:focus,
.wj-control .wj-input-group-btn > .wj-btn.wj-btn-default:hover {
  background: #e1e1e1;
  transition-duration: 0.4s;
}
.wj-control.wj-rtl .wj-input-group-btn:last-child:not(:first-child) > .wj-btn {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-left: none;
}
.wj-control.wj-rtl .wj-input-group-btn:first-child:not(:last-child) > .wj-btn {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-right: none;
}
.wj-control.wj-rtl .wj-btn-group > *:not(:first-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-left: none;
}
.wj-control.wj-rtl .wj-btn-group > .wj-form-control:not(:first-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-left: none;
}

.wj-inputnumber input.wj-form-control {
  text-align: right;
}

.wj-listbox {
  overflow: auto;
  cursor: default;
}
.wj-listbox .wj-listbox-item {
  padding: 4px 6px 3px 6px;
}
.wj-listbox .wj-listbox-item.wj-separator {
  height: 1px;
  margin: 3px 0;
  padding: 0;
  background: rgba(0, 0, 0, 0.1);
}
.wj-listbox .wj-listbox-item.wj-header {
  opacity: 1;
  position: sticky;
  top: 0;
}
.wj-listbox .wj-listbox-item:not(.wj-state-selected):not(.wj-state-disabled):not(.wj-separator):hover {
  background: rgba(0, 0, 0, 0.05);
  transition-duration: 0.4s;
}
.wj-listbox .wj-listbox-item > label {
  display: flex;
  align-content: flex-start;
  align-items: baseline;
  margin: 0;
  font-weight: normal;
}
.wj-listbox .wj-listbox-item > label > input[type=checkbox] {
  margin: 4px;
}

.wj-multiselectlistbox .wj-template {
  display: flex;
  flex-direction: column;
  max-height: inherit;
  min-height: inherit;
  height: auto;
}
.wj-multiselectlistbox .wj-content {
  display: block;
  border: none;
  border-radius: 0;
}
.wj-multiselectlistbox .wj-form-control {
  border: none;
  width: 100%;
  color: inherit;
  padding: 5px 8px;
}
.wj-multiselectlistbox .wj-form-control:focus, .wj-multiselectlistbox .wj-form-control:active {
  border: none !important;
  outline: none !important;
}

.wj-dropdown-panel > .wj-multiselectlistbox {
  display: block;
}

.wj-calendar {
  max-width: 100vw;
  overflow: auto;
}
.wj-calendar .wj-header {
  font-size: 90%;
}
.wj-calendar .wj-day-today {
  font-weight: bold;
}
.wj-calendar .wj-day-othermonth {
  opacity: 0.65;
}
.wj-calendar .wj-calendar-outer {
  display: block;
  padding: 10px;
  cursor: default;
}
.wj-calendar .wj-calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 7px;
  min-width: 19em;
}
.wj-calendar .wj-calendar-header .wj-month-select {
  display: inline-block;
  margin: 6px;
  font-weight: bold;
  cursor: pointer;
}
.wj-calendar .wj-calendar-header .wj-month-select .wj-glyph-down {
  opacity: 0.5;
}
.wj-calendar .wj-calendar-header .wj-btn-group .wj-btn {
  padding: 2px 8px 0 8px;
}
.wj-calendar .wj-calendar-header .wj-btn-group span {
  pointer-events: none;
}
.wj-calendar .wj-calendar-month {
  width: 100%;
  border-collapse: collapse;
  font: inherit;
}
.wj-calendar .wj-calendar-month td {
  width: 14.29%;
}
.wj-calendar .wj-calendar-year {
  width: 100%;
  border-collapse: collapse;
  font: inherit;
}
.wj-calendar .wj-calendar-year td {
  width: 25%;
}
.wj-calendar .wj-calendar-year tr:not(.wj-header) td {
  height: 3em;
}
.wj-calendar td {
  text-align: center;
  padding: 4px 6px 3px 6px;
  border: none;
}
.wj-calendar td.wj-state-invalid {
  opacity: 0.65;
}
.wj-calendar.wj-calendar-multimonth {
  display: inline-flex;
  flex-wrap: wrap;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.wj-calendar.wj-calendar-multimonth .wj-content {
  border-color: rgba(0, 0, 0, 0.05);
  border-radius: 0;
}
.wj-calendar.wj-calendar-multimonth .wj-calendar {
  display: flex;
}
.wj-calendar.wj-btns-outside {
  position: relative;
}
.wj-calendar.wj-btns-outside .wj-calendar-header .wj-btn-group {
  border: none;
}
.wj-calendar.wj-btns-outside .wj-calendar-header .wj-btn-group button {
  display: none;
  position: absolute;
  z-index: 1;
  top: 0;
  min-width: 1em;
  padding: 0;
  border: none;
  opacity: 0.5;
}
.wj-calendar.wj-btns-outside .wj-calendar-header .wj-btn-group button[wj-part=btn-prev] {
  left: 0;
  display: block;
}
.wj-calendar.wj-btns-outside .wj-calendar-header .wj-btn-group button[wj-part=btn-next] {
  right: 0;
  display: block;
}
.wj-calendar.wj-rtl .wj-calendar-header .wj-btn-group {
  float: left;
}
.wj-calendar.wj-rtl .wj-calendar-header .wj-btn-group .wj-btn .wj-glyph-right,
.wj-calendar.wj-rtl .wj-calendar-header .wj-btn-group .wj-btn .wj-glyph-left {
  transform: scale(-1, 1);
}

.wj-dropdown-panel .wj-calendar-outer {
  background: none;
}
.wj-dropdown-panel .wj-calendar-outer > .wj-content {
  display: block;
}

.wj-yearpicker {
  max-height: 12em;
  min-width: 18em;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 4em;
  align-items: center;
  justify-items: center;
  text-align: center;
}
.wj-yearpicker .wj-listbox-item {
  text-align: center;
  display: inline-block;
  width: 4em;
}

.wj-outer-flex, .wj-inputdate-dropdown .wj-calendar-outer, .wj-calendar.wj-calendar-multimonth .wj-calendar-outer {
  display: flex;
  flex-direction: column;
  min-width: 21em;
  flex-shrink: 0;
}

.wj-inputdate-dropdown {
  display: flex;
  background: white;
}
.wj-inputdate-dropdown > .wj-calendar {
  flex-grow: 1;
  border: none;
}
.wj-inputdate-dropdown > .wj-listbox {
  flex-grow: 1;
  margin: 10px;
  min-width: 8em;
  max-height: 16em;
}
.wj-inputdate-dropdown > .wj-listbox .wj-listbox-item {
  white-space: nowrap;
}

.wj-colorpicker {
  width: 420px;
  height: 200px;
  padding: 4px;
}
.wj-colorpicker .wj-colorbox {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.wj-inputcolor .wj-input-group input.wj-form-control {
  padding-left: 24px;
}
.wj-inputcolor .wj-inputcolorbox {
  position: absolute;
  left: 6px;
  top: 6px;
  bottom: 6px;
  width: 12px;
  border: 1px solid black;
}
.wj-inputcolor.wj-rtl .wj-input-group input.wj-form-control {
  padding-left: 4px;
  padding-right: 24px;
}
.wj-inputcolor.wj-rtl .wj-inputcolorbox {
  left: auto;
  right: 6px;
}

.wj-listbox.wj-dropdown-panel .wj-state-match {
  font-weight: bold;
}

.wj-multi-autocomplete {
  width: 100%;
  padding: 5px;
}
.wj-multi-autocomplete .wj-input {
  float: left;
}
.wj-multi-autocomplete .wj-input-group .wj-form-control {
  margin-top: 5px;
  height: auto;
}
.wj-multi-autocomplete .wj-token {
  background: #eee;
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 5px;
  vertical-align: middle;
  float: left;
  cursor: default;
}
.wj-multi-autocomplete .wj-token:focus, .wj-multi-autocomplete .wj-token:hover, .wj-multi-autocomplete .wj-token.wj-token-active {
  background: #e1e1e1;
  transition-duration: 0.4s;
}
.wj-multi-autocomplete .wj-token .wj-token-label {
  padding: 4px 6px 3px 6px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  max-width: 500px;
}
.wj-multi-autocomplete .wj-token .wj-token-close {
  padding: 4px 6px 3px 6px;
  color: black;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
}
.wj-multi-autocomplete .wj-token .wj-token-close:hover, .wj-multi-autocomplete .wj-token .wj-token-close:focus {
  opacity: 0.5;
  transition-duration: 0.4s;
}
.wj-multi-autocomplete .wj-token-helper {
  position: absolute;
  left: -10000px;
}

.wj-popup {
  background: white;
  box-shadow: 0 6px 13px rgba(0, 0, 0, 0.2);
}
.wj-popup .wj-dialog-header {
  width: 100%;
  background: #eee;
  color: #444;
  font-weight: bold;
  font-size: 120%;
  padding: 1em;
}
.wj-popup .wj-dialog-body {
  padding: 1em;
}
.wj-popup .wj-dialog-footer {
  text-align: right;
  margin-top: 1em;
  padding: 1em;
}

.wj-popup-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.wj-menu .wj-form-control {
  cursor: pointer;
}

.wj-listbox.wj-menu-items .wj-listbox-item:not(.wj-state-disabled):not(.wj-separator) {
  cursor: pointer;
}
.wj-listbox.wj-menu-items .wj-listbox-item.wj-subitems {
  position: relative;
}
.wj-listbox.wj-menu-items .wj-listbox-item.wj-subitems:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  opacity: 0.75;
  border-top: 0.4em solid transparent;
  border-bottom: 0.4em solid transparent;
  border-left: 0.5em solid;
}
.wj-listbox.wj-menu-items:not(.wj-rtl) .wj-listbox-item.wj-subitems {
  padding-right: 1.8em;
}
.wj-listbox.wj-menu-items:not(.wj-rtl) .wj-listbox-item.wj-subitems:after {
  right: 0.55em;
}
.wj-listbox.wj-menu-items.wj-rtl .wj-listbox-item.wj-subitems {
  padding-left: 1.8em;
}
.wj-listbox.wj-menu-items.wj-rtl .wj-listbox-item.wj-subitems:after {
  left: 0.55em;
  transform: translate(0, -50%) scale(-1, 1);
}

.wj-pager .wj-input-group .wj-input-group-btn > .wj-btn {
  min-width: 40px;
}
.wj-pager .wj-input-group .wj-form-control {
  text-align: center;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
.wj-labeled-input {
  position: relative;
  display: inline-block;
  width: 12em;
  max-width: 100%;
  margin: 0 20px;
  padding: 20px 0;
}
.wj-labeled-input, .wj-labeled-input *, .wj-labeled-input *:after, .wj-labeled-input *:before {
  box-sizing: border-box;
  box-sizing: border-box;
}
.wj-labeled-input.wide {
  width: 20em;
}
.wj-labeled-input .accesskey {
  text-decoration: underline;
  font-size: unset;
}
.wj-labeled-input .wj-control.wj-content {
  margin: 0;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.wj-labeled-input .wj-control.wj-content button {
  opacity: 0.75;
  border-color: rgba(0, 0, 0, 0.1);
}
.wj-labeled-input .wj-inputnumber.wj-state-empty:not(.wj-state-focused) .wj-btn {
  display: none;
}
.wj-labeled-input label {
  font-size: 16px;
  top: 24px;
  bottom: 0;
  margin: 0;
  pointer-events: none;
  position: absolute;
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  color: rgba(0, 0, 0, 0.258824);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.wj-labeled-input label:after {
  content: "";
  background-color: #0085c7;
  bottom: 20px;
  height: 2px;
  left: 45%;
  position: absolute;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  visibility: hidden;
  width: 10px;
}
.wj-labeled-input .wj-state-focused + label:after {
  left: 0;
  visibility: visible;
  width: 100%;
}
.wj-labeled-input .wj-error {
  color: red;
  position: absolute;
  font-size: 12px;
  margin-top: 3px;
  visibility: hidden;
  display: block;
}
.wj-labeled-input .wj-control.wj-state-invalid ~ .wj-error {
  visibility: visible;
}
.wj-labeled-input .wj-state-invalid + label {
  color: red;
}
.wj-labeled-input .wj-state-invalid + label:after {
  background-color: red;
}
.wj-labeled-input input[type=checkbox],
.wj-labeled-input input[type=radio] {
  display: none;
}
.wj-labeled-input input[type=checkbox] + label,
.wj-labeled-input input[type=radio] + label {
  position: relative;
  padding: 0.25em 0 0 1.8em;
  pointer-events: all;
  font-size: unset;
  top: 0;
  overflow: visible;
}
.wj-labeled-input input[type=checkbox] + label:after,
.wj-labeled-input input[type=radio] + label:after {
  content: "";
  position: absolute;
  display: block;
  visibility: visible;
  text-align: center;
  left: 0;
  top: 0.25em;
  width: 1.3em;
  height: 1.3em;
  color: white;
  background: #ddd;
  transition: all 0.3s;
}
.wj-labeled-input input[type=checkbox] + label:hover:after,
.wj-labeled-input input[type=radio] + label:hover:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}
.wj-labeled-input input[type=checkbox]:checked + label:after,
.wj-labeled-input input[type=radio]:checked + label:after {
  content: "✔";
  background: #0085c7;
}
.wj-labeled-input input[type=radio] + label:after {
  border-radius: 1em;
  transform: scale(1.1);
}
.wj-labeled-input input[type=radio]:checked + label:after {
  content: "";
  background: white;
  border: 0.45em solid #0085c7;
}
.wj-labeled-input.switch input[type=checkbox] + label {
  padding-left: 3.1em;
}
.wj-labeled-input.switch input[type=checkbox] + label:before {
  content: "";
  position: absolute;
  display: block;
  visibility: visible;
  left: 0;
  top: 0.5em;
  width: 2.5em;
  height: 1em;
  border-radius: 1em;
  background: #ddd;
  transition: all 0.3s;
}
.wj-labeled-input.switch input[type=checkbox] + label:after {
  content: "";
  left: 0;
  top: 0.25em;
  width: 1.5em;
  height: 1.5em;
  border-radius: 1em;
  border: 1px solid #bbb;
  background: white;
  transition: all 0.3s;
}
.wj-labeled-input.switch input[type=checkbox]:checked + label:before {
  background: #0085c7;
  opacity: 0.4;
}
.wj-labeled-input.switch input[type=checkbox]:checked + label:after {
  margin-left: 1.25em;
  border: 1px solid #0085c7;
  background: #0085c7;
}

.wj-static-labels .wj-labeled-input :not(.wj-state-focused) + label,
.wj-labeled-input .wj-state-focused + label,
.wj-labeled-input :not(.wj-state-empty) + label {
  font-size: 12px;
  top: 4px;
  color: #0085c7;
  visibility: visible;
}

/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
.wj-tabpanel {
  display: block;
}
.wj-tabpanel > div > .wj-tabpanes {
  overflow: auto;
  border-top: 1px solid #ddd;
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
}
.wj-tabpanel > div > .wj-tabpanes > .wj-tabpane:not(.wj-state-active) {
  display: none;
}
.wj-tabpanel > div > .wj-tabheaders > .wj-tabheader {
  position: relative;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  padding: 8px 12px;
  text-decoration: none;
  color: inherit;
  font-weight: bold;
  text-transform: uppercase;
}
.wj-tabpanel > div > .wj-tabheaders > .wj-tabheader.wj-state-active {
  background: white;
  color: #0085c7;
}
.wj-tabpanel > div > .wj-tabheaders > .wj-tabheader:not(.wj-state-active):not(.wj-state-disabled):focus {
  background: rgba(0, 0, 0, 0.1);
}
.wj-tabpanel > div > .wj-tabheaders > .wj-tabheader:after {
  content: "";
  position: absolute;
  background: #0085c7;
  height: 2.5px;
  left: 45%;
  width: 10%;
  bottom: 0;
  visibility: hidden;
}
.wj-tabpanel > div > .wj-tabheaders > .wj-tabheader.wj-state-active:after {
  left: 0;
  width: 100%;
  visibility: visible;
}
.wj-tabpanel.wj-animated > div > .wj-tabheaders > .wj-tabheader.wj-state-active:after {
  transition-duration: 0.2s;
}

.wj-accordion {
  overflow: auto;
}
.wj-accordion > .wj-content {
  border: none;
  overflow: hidden;
  display: none;
}
.wj-accordion > .wj-header.wj-state-active + .wj-content {
  display: block;
}
.wj-accordion > .wj-header {
  display: block;
  text-decoration: none;
  padding: 12px;
  font-size: 120%;
  cursor: pointer;
}
.wj-accordion > .wj-header.wj-state-active {
  background: #0085c7;
  color: white;
}
.wj-accordion > .wj-header:focus {
  outline: 2px solid #0085c7;
  outline-offset: -2px;
}
.wj-accordion.wj-show-icons > .wj-header:before {
  content: "";
  float: left;
  margin: 0.4em 0.5em 0.4em 0;
  opacity: 0.5;
  white-space: normal;
  border-top: 0.4em solid transparent;
  border-bottom: 0.4em solid transparent;
  border-left: 0.5em solid;
}
.wj-accordion.wj-show-icons > .wj-header.wj-state-active:before {
  border-top: 0.5em solid;
  border-left: 0.4em solid transparent;
  border-right: 0.4em solid transparent;
}
.wj-accordion.wj-rtl.wj-show-icons > .wj-header:before {
  float: right;
  margin: 0.4em 0 0.4em 0.5em;
  transform: scaleX(-1);
}

.wj-treeview {
  width: 100%;
  overflow: auto;
  border: none;
}
.wj-treeview .wj-nodelist {
  display: block;
  position: relative;
}
.wj-treeview .wj-nodelist .wj-nodelist {
  padding-left: 1em;
}
.wj-treeview .wj-node {
  cursor: pointer;
  white-space: nowrap;
  padding: 4px 6px 3px 6px;
}
.wj-treeview .wj-node:before {
  content: "";
  position: relative;
  display: inline-block;
  border-top: 0.5em solid;
  border-right: 0.45em solid transparent;
  border-left: 0.45em solid transparent;
  top: -1px;
  margin-right: 4px;
  opacity: 0.75;
}
.wj-treeview .wj-node.wj-state-selected {
  color: inherit;
  background: rgba(0, 0, 0, 0.05);
}
.wj-treeview .wj-node.wj-state-collapsed:before, .wj-treeview .wj-node.wj-state-collapsing:before {
  transform: rotate(-90deg);
}
.wj-treeview .wj-node.wj-state-collapsed.wj-state-loading:before {
  animation-name: wjLazyNodeLoading;
  animation-duration: 0.6s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}
.wj-treeview .wj-node.wj-state-empty:before {
  visibility: hidden;
}
.wj-treeview .wj-node.wj-state-collapsed + .wj-nodelist {
  display: none;
}
.wj-treeview .wj-node img {
  padding-right: 4px;
}
.wj-treeview .wj-node .wj-node-check {
  margin-right: 4px;
}
.wj-treeview .wj-node .wj-node-text {
  display: inline-block;
}
.wj-treeview .wj-marker {
  position: absolute;
  background: #0085c7;
  opacity: 0.5;
  pointer-events: none;
}
.wj-treeview.wj-state-loading * {
  cursor: wait;
}
.wj-treeview.wj-animated .wj-node:before {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.wj-treeview.wj-animated .wj-node.wj-state-collapsed:before, .wj-treeview.wj-animated .wj-node.wj-state-collapsing:before {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.wj-treeview.wj-rtl .wj-nodelist .wj-nodelist {
  padding-left: unset;
  padding-right: 1em;
}
.wj-treeview.wj-rtl .wj-nodelist .wj-node:before {
  margin-right: unset;
  margin-left: 4px;
}
.wj-treeview.wj-rtl .wj-nodelist .wj-node.wj-state-collapsed:before,
.wj-treeview.wj-rtl .wj-nodelist .wj-node.wj-state-collapsing:before {
  transform: rotate(90deg);
}
.wj-treeview.wj-rtl .wj-node img {
  padding-right: unset;
  padding-left: 4px;
}
.wj-treeview.wj-rtl .wj-node .wj-node-check {
  margin-right: unset;
  margin-left: 4px;
}

@keyframes wjLazyNodeLoading {
  from {
    opacity: 1;
    transform: scale(1.1);
  }
  to {
    opacity: 0.5;
    transform: scale(0.5);
  }
}
/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
.wj-flexgrid {
  width: 100%;
  cursor: default;
  overflow: hidden;
}
.wj-flexgrid:focus, .wj-flexgrid:active {
  outline: none !important;
}
.wj-flexgrid .wj-row {
  position: absolute;
  top: 0;
}
.wj-flexgrid .wj-colheaders .wj-header .wj-btn.wj-btn-glyph.wj-elem-pin {
  opacity: 0.9;
}
.wj-flexgrid .wj-colheaders .wj-header .wj-btn.wj-btn-glyph.wj-elem-pin:not(.wj-state-pinned) {
  opacity: 0.4;
}
.wj-flexgrid .wj-colheaders .wj-header .wj-btn.wj-btn-glyph.wj-elem-pin:not(.wj-state-pinned) .wj-glyph-pin {
  transform: rotate(-45deg);
}
.wj-flexgrid .wj-colheaders .wj-header.wj-state-multi-selected {
  border-bottom: 2px solid #0085c7;
}
.wj-flexgrid .wj-colheaders .wj-header.wj-colgroup {
  display: flex;
  align-items: center;
}
.wj-flexgrid .wj-colheaders .wj-header.wj-colgroup.wj-align-right {
  justify-content: flex-end;
}
.wj-flexgrid .wj-colheaders .wj-header.wj-colgroup.wj-align-center {
  justify-content: center;
}
.wj-flexgrid .wj-colheaders .wj-header.wj-big-header:after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  content: "";
}
.wj-flexgrid .wj-rowheaders .wj-header.wj-state-multi-selected {
  border-right: 2px solid #0085c7;
}
.wj-flexgrid .wj-rowheaders .wj-header.wj-big-header:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  content: "";
}
.wj-flexgrid .wj-topleft .wj-header.wj-big-header:after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  content: "";
}
.wj-flexgrid .wj-frozen-clone .wj-cell {
  pointer-events: auto;
}
.wj-flexgrid .wj-frozen-clone .wj-cell a {
  display: inline-block;
}
.wj-flexgrid .wj-marquee, .wj-flexgrid .wj-focus-header-cell {
  position: absolute;
  box-shadow: 0 0 0 1px #0085c7, inset 0 0 0 1px #0085c7;
  pointer-events: none;
}
.wj-flexgrid .wj-marker {
  position: absolute;
  background: #0085c7;
  opacity: 0.5;
  pointer-events: none;
  z-index: 1000;
}
.wj-flexgrid .wj-state-sticky .wj-header {
  opacity: 0.75;
}
.wj-flexgrid .wj-grid-editor {
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  background: inherit;
  color: inherit;
  overflow: hidden;
  resize: none;
}
.wj-flexgrid .wj-cell .wj-btn.wj-btn-glyph {
  padding: 0 4px;
  min-width: 1.5em;
  color: inherit;
  background: transparent;
  opacity: 0.4;
}
.wj-flexgrid .wj-cell .wj-btn.wj-btn-glyph:hover, .wj-flexgrid .wj-cell .wj-btn.wj-btn-glyph:focus {
  opacity: 1;
  transition-duration: 0.4s;
}
.wj-flexgrid .wj-cell .wj-btn.wj-btn-glyph.wj-right {
  float: right;
  order: 2;
}
.wj-flexgrid .wj-cell .wj-btn.wj-btn-glyph.wj-right:nth-child(1) {
  order: 5;
}
.wj-flexgrid .wj-cell .wj-btn.wj-btn-glyph.wj-right:nth-child(2) {
  order: 4;
}
.wj-flexgrid .wj-cell .wj-btn.wj-btn-glyph.wj-right:nth-child(3) {
  order: 3;
}
.wj-flexgrid .wj-cell .wj-btn.wj-btn-glyph.wj-elem-detail {
  min-width: 1em;
  padding: 0;
}
.wj-flexgrid .wj-cell .wj-btn.wj-btn-glyph > span {
  opacity: 1;
}
.wj-flexgrid .wj-cell.wj-hasdropdown {
  display: flex;
}
.wj-flexgrid .wj-cell.wj-hasdropdown > div {
  flex-grow: 1;
}
.wj-flexgrid .wj-cell.wj-hasdropdown .wj-btn.wj-btn-glyph.wj-right {
  height: 100%;
}
.wj-flexgrid .wj-cell.wj-radio-map label {
  display: inline-block;
  font-weight: inherit;
  margin: 0 1em 0 0;
}
.wj-flexgrid .wj-cell.wj-radio-map label input[type=radio] {
  margin: 0 4px 0 0;
  outline: none;
}
.wj-flexgrid .wj-cell.wj-radio-map label input[type=radio]:not(:checked) {
  opacity: 0.4;
}
.wj-flexgrid .wj-cell.wj-detail .wj-content.wj-flexgrid {
  vertical-align: top;
}
.wj-flexgrid .wj-cell.wj-detail {
  z-index: 0;
}
.wj-flexgrid .wj-cell label {
  display: inline-block;
  max-width: 100%;
  margin: 0;
  font-weight: inherit;
  line-height: 1em;
}
.wj-flexgrid .wj-cell label input[type=checkbox] {
  margin: 0px 6px 0px 0px;
}
.wj-flexgrid.wj-rtl .wj-cell {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-right: none;
}
.wj-flexgrid.wj-rtl .wj-cell .wj-btn.wj-btn-glyph.wj-right {
  float: left;
}
.wj-flexgrid.wj-rtl .wj-frozen-col {
  border-left: 1px solid #a2a2a2;
  border-right: none;
}
.wj-flexgrid.wj-rtl .wj-colheaders .wj-header.wj-filter-on, .wj-flexgrid.wj-rtl .wj-colheaders .wj-header.wj-filter-off {
  white-space: normal;
  word-break: break-all;
}
.wj-flexgrid.wj-rtl .wj-colheaders .wj-header.wj-big-header:after {
  left: 2px;
  right: unset;
}
.wj-flexgrid.wj-rtl .wj-colheaders .wj-header .wj-sort-index {
  padding: 0 0.2em 0 0;
}
.wj-flexgrid.wj-rtl .wj-rowheaders .wj-header.wj-state-multi-selected {
  border-left: 2px solid #0085c7;
  border-right: none;
}
.wj-flexgrid.wj-rtl .wj-topleft .wj-header.wj-big-header:after {
  left: 2px;
  right: unset;
}
.wj-flexgrid.wj-whitespace-pre .wj-cell {
  white-space: pre;
}
.wj-flexgrid.wj-whitespace-pre .wj-cell.wj-wrap {
  white-space: pre-wrap;
}
.wj-flexgrid.wj-whitespace-pre .wj-cell.wj-multiline {
  white-space: pre-wrap;
}
.wj-flexgrid [wj-state-measuring].wj-cell .wj-btn.wj-btn-glyph.wj-right {
  float: none;
}

.wj-cell {
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  padding: 4px 6px 3px 6px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background: white;
  outline: none;
}
.wj-cell:not(.wj-hasdropdown) {
  text-overflow: ellipsis;
}
.wj-cell.wj-alt {
  background: #f7f7f7;
}
.wj-cell.wj-header {
  background: #eee;
  color: #444;
  font-weight: bold;
}
.wj-cell.wj-header.wj-state-multi-selected {
  background: #e1e1e1;
  color: #444;
  font-weight: bold;
}
.wj-cell.wj-header .wj-sort-index {
  display: inline-block;
  font-weight: normal;
  font-size: 80%;
  vertical-align: top;
  color: #0085c7;
  padding: 0 0 0 0.2em;
  opacity: 1;
}
.wj-cell.wj-frozen-row {
  border-bottom: 1px solid #a2a2a2;
}
.wj-cell.wj-frozen-col {
  border-right: 1px solid #a2a2a2;
}
.wj-cell.wj-group {
  background: #e1e1e1;
  color: #444;
}
.wj-cell.wj-state-invalid {
  border: 1.2px solid red;
}
.wj-cell.wj-state-invalid.wj-header {
  background: rgb(255, 180, 180);
}
.wj-cell.wj-state-invalid:not(.wj-header):after {
  position: absolute;
  content: "";
  width: 0;
  right: 0;
  top: -6px;
  border: 6px solid transparent;
  border-right-color: red;
}
.wj-cell.wj-header.wj-state-dragsrc {
  opacity: 0.5;
}
.wj-cell.wj-wrap {
  white-space: normal;
  text-overflow: clip;
  word-wrap: break-word;
}
.wj-cell.wj-multiline {
  white-space: pre;
  text-overflow: clip;
  word-wrap: break-word;
}
.wj-cell.wj-wrap.wj-multiline {
  white-space: pre-wrap;
  text-overflow: clip;
}
.wj-cell.wj-align-left {
  text-align: left;
}
.wj-cell.wj-align-right {
  text-align: right;
}
.wj-cell.wj-align-center {
  text-align: center;
}
.wj-cell.wj-align-justify {
  text-align: justify;
}
.wj-cell.wj-align-justify-all {
  text-align: justify;
  text-align-last: justify;
  text-justify: distribute;
}

.wj-cells .wj-cell.wj-state-selected {
  background: #0085c7;
  color: white;
}
.wj-cells .wj-cell.wj-state-multi-selected {
  background: #80adbf;
  color: white;
}

/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
.wj-flexgrid .wj-cell.wj-filter-on .wj-elem-filter {
  opacity: 0.9;
}
.wj-flexgrid .wj-cell.wj-filter-off .wj-elem-filter {
  opacity: 0.5;
}

.wj-columnfiltereditor {
  padding: 10px;
  min-width: 240px;
  max-width: 400px;
}
.wj-columnfiltereditor .wj-filtertype {
  text-align: right;
  margin: 14px 0px;
  font-size: 12px;
}
.wj-columnfiltereditor .wj-control {
  margin-bottom: 6px;
  width: 100%;
}
.wj-columnfiltereditor .wj-valuefilter-editor,
.wj-columnfiltereditor .wj-conditionfilter-editor {
  margin-top: 1em;
}
.wj-columnfiltereditor .wj-conditionfilter-editor > div > label {
  display: block;
  margin-bottom: 0px;
  font-weight: normal;
}
.wj-columnfiltereditor .wj-listbox {
  height: 150px;
}
.wj-columnfiltereditor .wj-listbox:not(.wj-state-focused) .wj-listbox-item.wj-state-selected {
  color: inherit;
  background: transparent;
}
.wj-columnfiltereditor .wj-listbox:not(.wj-state-focused) .wj-listbox-item.wj-state-selected:hover {
  background: rgba(0, 0, 0, 0.05);
  transition-duration: 0.4s;
}
.wj-columnfiltereditor label {
  display: inline-block;
  width: auto;
}
.wj-columnfiltereditor a {
  color: inherit;
  font-size: inherit;
  font-weight: bold;
}
.wj-columnfiltereditor a.wj-state-disabled {
  text-decoration: none;
}
.wj-columnfiltereditor .wj-sort-buttons .wj-btn {
  min-width: 95px;
}

/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
.wj-grouppanel {
  display: block;
  background: #e1e1e1;
  padding: 15px;
  min-height: 1em;
}
.wj-grouppanel .wj-groupmarker {
  margin-right: 10px;
  padding: 6px 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.wj-grouppanel .wj-groupmarker:hover {
  background: #e1e1e1;
}
.wj-grouppanel .wj-groupmarker .wj-glyph-drag {
  margin: 0 10px 0 0;
}
.wj-grouppanel .wj-groupmarker .wj-filter {
  margin: 0 0 0 10px;
  cursor: pointer;
  opacity: 0.5;
}
.wj-grouppanel .wj-groupmarker .wj-filter:hover, .wj-grouppanel .wj-groupmarker .wj-filter.wj-filter-on {
  opacity: 1;
}
.wj-grouppanel .wj-groupmarker .wj-remove {
  cursor: pointer;
  opacity: 0.5;
  padding: 12px 0 12px 12px;
}
.wj-grouppanel .wj-groupmarker .wj-remove:hover {
  opacity: 1;
}
.wj-grouppanel .wj-groupmarker span {
  opacity: 0.5;
}
.wj-grouppanel .wj-groupmarker span:hover {
  opacity: 1;
}
.wj-grouppanel.wj-rtl .wj-groupmarker .wj-glyph-drag {
  margin: 0 0 0 10px;
}
.wj-grouppanel.wj-rtl .wj-groupmarker .wj-filter {
  margin: 0 10px 0 0;
}
.wj-grouppanel.wj-rtl .wj-groupmarker .wj-remove {
  padding: 12px 12px 12px 0;
}

/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
.wj-content.wj-flexgridsearch {
  vertical-align: middle;
  display: inline-table;
  border-collapse: separate;
}

.wj-flexgrid .wj-cell span.wj-state-match {
  background: yellow;
  color: black;
  font-weight: bold;
}

/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
.wj-flexgrid .wj-cell input.wj-column-selector {
  margin: 4px;
  vertical-align: bottom;
  outline: none;
}
.wj-flexgrid .wj-cell input.wj-column-selector.wj-column-selector-group {
  transform: scale(1.25);
  margin-right: 6px;
}

/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
.wj-flexgrid .wj-cell .wj-cell-maker {
  width: 100%;
  height: 100%;
}
.wj-flexgrid .wj-cell:not([wj-state-measuring]) button.wj-cell-maker {
  position: absolute;
  left: 0;
  top: 0;
  color: black;
}
.wj-flexgrid .wj-cell.wj-state-selected a.wj-cell-maker, .wj-flexgrid .wj-cell.wj-state-multi-selected a.wj-cell-maker {
  color: inherit;
}
.wj-flexgrid .wj-cell img.wj-cell-maker {
  width: unset;
}
.wj-flexgrid .wj-cell .wj-cell-maker svg {
  width: 100%;
  height: 100%;
  overflow: visible;
  stroke: #376092;
  fill: #376092;
}
.wj-flexgrid .wj-cell .wj-cell-maker svg rect, .wj-flexgrid .wj-cell .wj-cell-maker svg circle {
  stroke-width: 0;
}
.wj-flexgrid .wj-cell .wj-cell-maker svg line {
  stroke-width: 2px;
}
.wj-flexgrid .wj-cell .wj-cell-maker svg line.x-axis {
  stroke-width: 0.5px;
  stroke-opacity: 0.5;
}
.wj-flexgrid .wj-cell .wj-cell-maker svg .wj-marker {
  fill: #d00000;
  opacity: 1;
}
.wj-flexgrid .wj-cell.wj-cell-maker.wj-radio-map label {
  position: relative;
  width: 1em;
  transform: scale(0.1);
  color: orange;
  margin: 0 1em 0 0;
}
.wj-flexgrid .wj-cell.wj-cell-maker.wj-radio-map label input, .wj-flexgrid .wj-cell.wj-cell-maker.wj-radio-map label input:not(:checked) {
  opacity: 0;
}
.wj-flexgrid .wj-cell.wj-cell-maker.wj-radio-map label:after {
  position: absolute;
  transform: scale(20);
  cursor: pointer;
  content: "★";
  height: 100%;
}
.wj-flexgrid .wj-cell.wj-cell-maker.wj-radio-map label:hover {
  opacity: 1;
  color: orangered;
  transition: color 600ms;
}
.wj-flexgrid .wj-cell.wj-cell-maker.wj-radio-map label.wj-chk-hidden {
  opacity: 0;
  margin: 0 0 0 -1em;
}
.wj-flexgrid .wj-cell.wj-cell-maker.wj-radio-map label.wj-chk-off:not(:hover):not(.wj-chk-hidden) {
  opacity: 0.15;
}
.wj-flexgrid .wj-cell.wj-cell-maker.wj-radio-map[aria-readonly=true] label {
  pointer-events: none;
}

/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
.wj-flexchart {
  height: 400px;
  padding: 15px 10px;
  margin-bottom: 12px;
  background: white;
  border: 1px solid #e4e4e4;
  display: block;
  background: transparent;
}
.wj-flexchart .wj-header .wj-title {
  font-size: 16pt;
  fill: #666;
  font-weight: normal;
}
.wj-flexchart .wj-footer .wj-title {
  fill: #666;
  font-weight: normal;
}
.wj-flexchart .wj-label, .wj-flexchart .wj-data-label {
  fill: #666;
}
.wj-flexchart .wj-data-label-border {
  stroke: rgba(128, 128, 128, 0.5);
}
.wj-flexchart .wj-data-label-line {
  stroke: rgb(128, 128, 128);
}
.wj-flexchart .wj-axis-x .wj-title,
.wj-flexchart .wj-axis-y .wj-title,
.wj-flexchart .wj-legend .wj-title {
  font-style: italic;
}
.wj-flexchart .wj-axis-x .wj-line {
  stroke: #aaa;
  stroke-width: 1px;
}
.wj-flexchart .wj-gridline, .wj-flexchart .wj-gridline-minor {
  stroke: #666;
  stroke-width: 1px;
  opacity: 0.75;
}
.wj-flexchart .wj-gridline-minor {
  opacity: 0.25;
}
.wj-flexchart .wj-tick, .wj-flexchart .wj-tick-minor {
  stroke: #aaa;
  stroke-width: 1px;
}
.wj-flexchart .wj-state-selected {
  stroke-width: 3px;
  stroke-dasharray: 6;
  stroke-linecap: square;
}
.wj-flexchart.wj-flexchart-touch-disabled {
  touch-action: none;
  -ms-touch-action: none;
}
.wj-flexchart .wj-chart-linemarker {
  background: rgba(128, 128, 128, 0.3);
  position: absolute;
  padding: 2px;
}
.wj-flexchart .wj-chart-linemarker .wj-chart-linemarker-hline {
  height: 2px;
  background: rgb(128, 128, 128);
  position: absolute;
  touch-action: none;
  -ms-touch-action: none;
}
.wj-flexchart .wj-chart-linemarker .wj-chart-linemarker-vline {
  width: 2px;
  background: rgb(128, 128, 128);
  position: absolute;
  touch-action: none;
  -ms-touch-action: none;
}
.wj-flexchart .wj-chart-linemarker-container {
  position: relative;
}
.wj-flexchart.wj-chart-linemarker-draggable,
.wj-flexchart .wj-chart-linemarker .wj-chart-linemarker-draggable {
  cursor: move;
}

@media (max-width: 1025px) {
  .wj-flexchart .wj-state-selected {
    stroke-width: 2px;
    stroke-dasharray: 4;
  }
}
@media (max-width: 767px) {
  wj-flexchart .wj-state-selected {
    stroke-width: 1px;
    stroke-dasharray: 3;
  }
}
/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
.wj-treemap .wj-data-label {
  fill: white;
}

/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
.wj-flexchart .wj-chart-rangeslider {
  position: absolute;
  touch-action: none;
  -ms-touch-action: none;
}
.wj-flexchart .wj-chart-rangeslider button {
  position: absolute;
  text-align: center;
  vertical-align: middle;
  padding: 0;
  line-height: 16px;
  border-radius: 2px;
}
.wj-flexchart .wj-chart-rangeslider .wj-rangeslider-content {
  background: #eaeaea;
  height: 100%;
  position: relative;
}
.wj-flexchart .wj-chart-rangeslider .wj-rangeslider-rangehandle {
  position: absolute;
  text-align: center;
  vertical-align: middle;
  background: #bdbdbd;
  height: 100%;
  width: 100%;
}
.wj-flexchart .wj-chart-rangeslider .wj-rangeslider-minhandle,
.wj-flexchart .wj-chart-rangeslider .wj-rangeslider-maxhandle {
  border: 1px solid gray;
  display: block;
  position: absolute;
  border-radius: 4px;
}
.wj-flexchart .wj-chart-rangeslider .wj-rangeslider-handle-active {
  z-index: 2;
}
.wj-flexchart .wj-chart-rangeslider .wj-glyph-left {
  border-top: 5px solid transparent;
  border-right: 4px solid;
  border-bottom: 4px solid transparent;
  margin-right: 2px;
}
.wj-flexchart .wj-chart-rangeslider .wj-glyph-right {
  border-bottom: 5px solid transparent;
  border-left: 4px solid;
  border-top: 4px solid transparent;
  margin-left: 2px;
}
.wj-flexchart .wj-chart-rangeslider .wj-glyph-down {
  border-top: 5px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin-bottom: 3px;
}
.wj-flexchart .wj-chart-rangeslider .wj-glyph-up {
  border-right: 4px solid transparent;
  border-bottom: 5px solid;
  border-left: 4px solid transparent;
  margin-bottom: 4px;
}
.wj-flexchart .wj-chart-hrangeslider .wj-rangeslider-minhandle,
.wj-flexchart .wj-chart-hrangeslider .wj-rangeslider-maxhandle {
  background: #bdbdbd;
  cursor: ew-resize;
  height: 22px;
  margin-top: -2px;
  width: 14px;
}
.wj-flexchart .wj-chart-hrangeslider .wj-rangeslider-decbtn {
  left: 0;
}
.wj-flexchart .wj-chart-hrangeslider .wj-rangeslider-incbtn {
  right: 0;
}
.wj-flexchart .wj-chart-hrangeslider button {
  width: 16px;
  height: 100%;
}
.wj-flexchart .wj-chart-vrangeslider .wj-rangeslider-minhandle,
.wj-flexchart .wj-chart-vrangeslider .wj-rangeslider-maxhandle {
  background: #bdbdbd;
  cursor: ns-resize;
  width: 22px;
  margin-left: -2px;
  height: 14px;
}
.wj-flexchart .wj-chart-vrangeslider .wj-rangeslider-decbtn {
  bottom: 0;
}
.wj-flexchart .wj-chart-vrangeslider .wj-rangeslider-incbtn {
  top: 0;
}
.wj-flexchart .wj-chart-vrangeslider button {
  height: 16px;
  width: 100%;
}
.wj-flexchart .wj-chart-rangeselector-container {
  position: relative;
}
.wj-flexchart .wj-chart-rangeselector-container .wj-chart-rangeslider,
.wj-flexchart .wj-chart-rangeselector-container .wj-rangeslider-content {
  background: transparent;
  border-color: transparent;
}
.wj-flexchart .wj-chart-rangeselector-container .wj-rangeslider-rangehandle {
  opacity: 0.3;
}
.wj-flexchart .wj-chart-rangeselector-container .wj-rangeslider-minhandle, .wj-flexchart .wj-chart-rangeselector-container .wj-rangeslider-maxhandle {
  background: transparent;
  opacity: 0.6;
  border: 2px solid Gray;
  border-radius: 0.5em;
}
.wj-flexchart .wj-chart-rangeselector-container .wj-chart-hrangeslider .wj-rangeslider-minhandle, .wj-flexchart .wj-chart-rangeselector-container .wj-chart-hrangeslider .wj-rangeslider-maxhandle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 1px solid hsla(0, 0%, 50.2%, 0.75);
  color: hsl(0, 0%, 50.2%);
  background: #d3d3d3;
  opacity: 1;
}
.wj-flexchart .wj-chart-rangeselector-container .wj-chart-hrangeslider .wj-rangeslider-minhandle:after, .wj-flexchart .wj-chart-rangeselector-container .wj-chart-hrangeslider .wj-rangeslider-maxhandle:after {
  content: "║";
  text-align: center;
  width: 100%;
  display: inline-block;
  position: absolute;
  margin: 0;
  top: 50%;
  transform: translateY(-55%);
  opacity: 0.75;
  font-size: 10px;
}
.wj-flexchart .wj-chart-rangeselector-container .wj-chart-vrangeslider .wj-rangeslider-minhandle, .wj-flexchart .wj-chart-rangeselector-container .wj-chart-vrangeslider .wj-rangeslider-maxhandle {
  height: 20px;
  width: 20px;
  left: 50%;
  border-radius: 50%;
  border: 1px solid hsla(0, 0%, 50.2%, 0.75);
  top: 0;
  bottom: 0;
  color: hsl(0, 0%, 50.2%);
  background: #d3d3d3;
  opacity: 1;
}
.wj-flexchart .wj-chart-rangeselector-container .wj-chart-vrangeslider .wj-rangeslider-minhandle:after, .wj-flexchart .wj-chart-rangeselector-container .wj-chart-vrangeslider .wj-rangeslider-maxhandle:after {
  content: "═";
  text-align: center;
  height: 100%;
  display: inline-block;
  position: absolute;
  margin: 0;
  left: 50%;
  transform: translate(-50%, 15%);
  opacity: 0.75;
  font-size: 12px;
}
.wj-flexchart .wj-chart-rangeselector-container .wj-chart-rangeslider .wj-rangeslider-minhandle.wj-rangeslider-handle-active,
.wj-flexchart .wj-chart-rangeselector-container .wj-chart-rangeslider .wj-rangeslider-maxhandle.wj-rangeslider-handle-active {
  background: rgba(136, 189, 230, 0.7);
}
.wj-flexchart .wj-rangeselector .wj-scroller-center {
  background: rgba(128, 128, 128, 0.1);
  position: absolute;
  display: block;
  touch-action: none;
  -ms-touch-action: none;
}
.wj-flexchart.wj-panable {
  cursor: pointer;
}
.wj-flexchart .wj-zoom {
  visibility: hidden;
  position: relative;
}
.wj-flexchart .wj-zoom-overlay {
  background: rgba(128, 128, 128, 0.2);
  position: absolute;
  display: block;
  touch-action: none;
  -ms-touch-action: none;
}
.wj-flexchart .wj-block-other-interaction {
  display: none;
}

/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
.wj-flexmap .wj-btn {
  opacity: 0.9;
  padding: 10px 10px 6px 10px !important;
  border: solid 0.5px gray;
}

/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
.wj-gauge {
  background: transparent;
}
.wj-gauge:not(.wj-state-readonly) {
  touch-action: none;
}
.wj-gauge .wj-face path {
  fill: #f5f5f5;
  stroke: #cbcbcb;
  stroke-width: 1px;
  stroke-linejoin: round;
}
.wj-gauge .wj-pointer,
.wj-gauge .wj-needle {
  fill: #0085c7;
}
.wj-gauge .wj-ticks {
  stroke-width: 2px;
  stroke: white;
}
.wj-gauge .wj-min,
.wj-gauge .wj-max,
.wj-gauge .wj-tick-text text {
  font-size: 60%;
  opacity: 0.5;
}
.wj-gauge text {
  fill: currentColor;
}
.wj-gauge svg:not(:root) {
  overflow: visible;
}
.wj-gauge g {
  cursor: inherit;
}

.wj-radialgauge .wj-value {
  font-size: 150%;
}

.wj-lineargauge {
  height: 1.2em;
}
.wj-lineargauge .wj-value {
  fill: white;
}

/*!
    *
    * Wijmo Library 5.20231.904
    * http://wijmo.com/
    *
    * Copyright(c) GrapeCity, Inc.  All rights reserved.
    *
    * Licensed under the GrapeCity Commercial License.
    * sales@wijmo.com
    * wijmo.com/products/wijmo-5/license/
    *
    */
.wj-barcode {
  height: 60px;
  display: inline-block;
}
.wj-barcode.wj-state-invalid {
  border: 1.2px solid red;
}

.wj-barcode-qrcode {
  width: 100px;
  height: 100px;
}

.wj-barcode-ean8 {
  width: 85px;
}

.wj-barcode-ean13 {
  width: 140px;
}

.wj-barcode-upca {
  width: 115px;
}

.wj-barcode-upce0 {
  width: 70px;
}

.wj-barcode-upce1 {
  width: 70px;
}

.wj-barcode-gs1databar-omnidirectional {
  width: 100px;
}

.wj-barcode-gs1databar-truncated {
  width: 100px;
}

.wj-barcode-gs1databar-stacked {
  width: 60px;
}

.wj-barcode-gs1databar-stacked-omnidirectional {
  width: 60px;
}

.wj-barcode-gs1databar-limited {
  width: 85px;
}

.wj-barcode-micropdf417 {
  width: 105px;
}

.wj-barcode-datamatrix {
  width: 85px;
  height: 85px;
}

.wj-barcode-code49 {
  width: 85px;
}

.wj-barcode-itf14 {
  width: 170px;
}

.wj-barcode-japanese-postal {
  width: 140px;
  height: 40px;
}