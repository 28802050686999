@keyframes open {
  0% {
    opacity: 0%;
    transform: translate(0, -100%);
  }

  100% {
    opacity: 100%;
    transform: translate(0, 0);
  }
}

@keyframes close {
  0% {
    opacity: 100%;
    transform: translate(0, 0);
  }

  50% {
    opacity: 0%;
  }

  100% {
    opacity: 0%;
    transform: translate(0, 100%);
  }
}
