@import url('ui/fonts/font.style.css');
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  width: 100%;
  height: 100%;
  font-family: "Malgun Gothic","Segoe UI","MS YaHei", Sans-Serif;
}


a {
  vertical-align: middle;
  text-decoration: none;
  color: inherit;
}

* {
  box-sizing: border-box;
}

/* .MuiAutocomplete-popper.MuiPopper-root[role="presentation"] {
  right:0px !important;
}

.MuiAutocomplete-popper.MuiPopper-root[role="presentation"] > .MuiAutocomplete-paper {
  border:1px solid #B9BCBB;
  border-radius:2px;
  box-shadow:0px 4px 5px rgba(0,0,0,0.11), 0px 0px 2px rgba(0,0,0,0.17);
  padding:8px 0px !important;
  font-weight:400;
  font-size:13px;
  line-height:1.5;
  color:#1F1F1F;
}

.MuiAutocomplete-popper.MuiPopper-root[role="presentation"] > .MuiAutocomplete-paper > ul {
  padding:0px !important;
}

.MuiAutocomplete-popper.MuiPopper-root[role="presentation"] > .MuiAutocomplete-paper > ul > li[role="option"] {
  padding:6px 8px !important;
  min-height:32px !important;
  font-weight:400;
  font-size:13px;
  line-height:1.5;
  color:#1F1F1F;
}

.MuiAutocomplete-popper.MuiPopper-root[role="presentation"] > .MuiAutocomplete-paper > ul > li.MuiAutocomplete-option[aria-selected="true"] {
  background:#EEEAF8 !important;
}

.MuiAutocomplete-popper.MuiPopper-root[role="presentation"] > .MuiAutocomplete-paper > ul > li[role="option"]:hover {
  background:#EEEAF8 !important;
}

.MuiAutocomplete-popper.MuiPopper-root[role="presentation"] > .MuiAutocomplete-paper > ul > li[role="option"].MuiAutocomplete-option.Mui-focused {
  background:#FFF;
}

.MuiMenu-paper {
  border:1px solid #979998;
  border-radius:2px !important;
  box-shadow:0px 0px 2px rgba(0,0,0,0.17), 0px 5px 2px rgba(0,0,0,0.11);
}

li.MuiMenuItem-gutters {
  font-size:13px;
  font-weight:normal !important;
  line-height:1.5;
  color:#1F1F1F;
  height:32px !important;
  min-height:32px !important;
  font-family:"Malgun Gothic","Segoe UI","MS YaHei", sans-serif;
  padding-left:8px;
}

li.MuiMenuItem-gutters {
  position:relative;
}

li.MuiMenuItem-gutters > span {
  display:none;
}

li.MuiMenuItem-gutters:focus:before,
li.MuiMenuItem-gutters:active:before {
  content:"";
  clear:both;
  display:inline-block;
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  right:8px;
  width: 16px;
  height:16px;
  background-color: #554596;
  -webkit-mask:url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.6343 5.23431C11.9467 4.9219 12.4533 4.9219 12.7657 5.23431C13.0781 5.54673 13.0781 6.05327 12.7657 6.36568L7.4217 11.7097C6.81646 12.3149 5.8352 12.315 5.22986 11.7099L2.23441 8.71548C1.92194 8.40311 1.92185 7.89658 2.23422 7.58411C2.54658 7.27163 3.05311 7.27154 3.36559 7.58391L6.32567 10.543L11.6343 5.23431Z' fill='white'/%3E%3C/svg%3E%0A");
  mask-image:url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.6343 5.23431C11.9467 4.9219 12.4533 4.9219 12.7657 5.23431C13.0781 5.54673 13.0781 6.05327 12.7657 6.36568L7.4217 11.7097C6.81646 12.3149 5.8352 12.315 5.22986 11.7099L2.23441 8.71548C1.92194 8.40311 1.92185 7.89658 2.23422 7.58411C2.54658 7.27163 3.05311 7.27154 3.36559 7.58391L6.32567 10.543L11.6343 5.23431Z' fill='white'/%3E%3C/svg%3E%0A");
}

li.MuiMenuItem-gutters:hover {
  background:#EEEAF8;
}

li.MuiMenuItem-gutters.Mui-selected {
  background-color:#EEEAF8;
  position:relative;
}

li.MuiMenuItem-gutters.Mui-selected:hover {
  background-color:#EEEAF8;
}

li.MuiMenuItem-gutters.Mui-selected:before {
  content:"";
  clear:both;
  display:inline-block;
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  right:8px;
  width: 16px;
  height:16px;
  background-color: #554596;
  -webkit-mask:url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.6343 5.23431C11.9467 4.9219 12.4533 4.9219 12.7657 5.23431C13.0781 5.54673 13.0781 6.05327 12.7657 6.36568L7.4217 11.7097C6.81646 12.3149 5.8352 12.315 5.22986 11.7099L2.23441 8.71548C1.92194 8.40311 1.92185 7.89658 2.23422 7.58411C2.54658 7.27163 3.05311 7.27154 3.36559 7.58391L6.32567 10.543L11.6343 5.23431Z' fill='white'/%3E%3C/svg%3E%0A");
  mask-image:url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.6343 5.23431C11.9467 4.9219 12.4533 4.9219 12.7657 5.23431C13.0781 5.54673 13.0781 6.05327 12.7657 6.36568L7.4217 11.7097C6.81646 12.3149 5.8352 12.315 5.22986 11.7099L2.23441 8.71548C1.92194 8.40311 1.92185 7.89658 2.23422 7.58411C2.54658 7.27163 3.05311 7.27154 3.36559 7.58391L6.32567 10.543L11.6343 5.23431Z' fill='white'/%3E%3C/svg%3E%0A");
}
 */



/* .wj-cell[role="gridcell"] {
  position:relative !important;
} */


/* span.MuiButtonBase-root.MuiCheckbox-root {
  display:inline-block;
  width:16px;
  height:16px;
  padding:0px;
  margin-right:8px;
  border:1px solid #B9BCBB;
  border-radius:2px;
  background:#FFF;
}

span.MuiButtonBase-root.MuiCheckbox-root > svg {
  display:none;
}

span.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
  border:1px solid #554596;
  background-color:#554596;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.6343 5.23431C11.9467 4.9219 12.4533 4.9219 12.7657 5.23431C13.0781 5.54673 13.0781 6.05327 12.7657 6.36568L7.4217 11.7097C6.81646 12.3149 5.8352 12.315 5.22986 11.7099L2.23441 8.71548C1.92194 8.40311 1.92185 7.89658 2.23422 7.58411C2.54658 7.27163 3.05311 7.27154 3.36559 7.58391L6.32567 10.543L11.6343 5.23431Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size:14px 14px;
  background-position:center;
  background-repeat:no-repeat;
} */