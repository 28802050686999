.dropzone.active {
  border-color: #1e90ff;
}

.dropzone {
  border: 2px dashed #bbb;
  border-radius: 5px;
  padding: 25px;
  text-align: center;
  cursor: pointer;
  font-size: 1.2rem;
  transition: all 0.2s;
}

.dropzone.active {
  background-color: #eee;
}

.dropzone ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropzone li {
  margin-top: 10px;
  font-size: 0.9rem;
}

.delete-button {
  background-color: red;
  color: white;
  border: none;
  padding: 1px;
  border-radius: 3px;
  margin-left: 10px;
  cursor: pointer;
}

.delete-cancel-button {
  background-color: blue;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 3px;
  margin-left: 10px;
  cursor: pointer;
}
