/* http://meyerweb.com/eric/tools/css/reset/
   v5.0.1 | 20191019
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}
body {
  line-height: 1;
}
menu, ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

input::placeholder {
  font-size:13px;
  line-height:1.5;
  font-weight:400;
  color:#979998;
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 700;
  src: local('Spoqa Han Sans Neo Bold'),
  url('./SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff2') format('woff2'),
  url('./SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff') format('woff'),
  url('./SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.ttf') format('truetype');
}


@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 500;
  src: local('Spoqa Han Sans Neo Medium'),
  url('./SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.woff2') format('woff2'),
  url('./SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.woff') format('woff'),
  url('./SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 400;
  src: local('Spoqa Han Sans Neo Regular'),
  url('./SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.woff2') format('woff2'),
  url('./SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.woff') format('woff'),
  url('./SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 300;
  src: local('Spoqa Han Sans Neo Light'),
  url('./SpoqaHanSansNeo/SpoqaHanSansNeo-Light.woff2') format('woff2'),
  url('./SpoqaHanSansNeo/SpoqaHanSansNeo-Light.woff') format('woff'),
  url('./SpoqaHanSansNeo/SpoqaHanSansNeo-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 100;
  src: local('Spoqa Han Sans Neo Thin'),
  url('./SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.woff2') format('woff2'),
  url('./SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.woff') format('woff'),
  url('./SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.ttf') format('truetype');
}
